"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineUserEdit = exports.UserEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const Constants = require("./constants");
const lib_es2015_3 = require("@hodol/webapp-common/lib-es2015");
const entity_1 = require("./entity");
const apis_2 = require("../file-permission/apis");
const file_permission_list_editor_1 = require("../file-permission/file-permission-list-editor");
class UserEdit extends HTMLElement {
    constructor() {
        super();
        this.userApis = new apis_1.default();
        this.filePermissionApis = new apis_2.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.serverProps = (0, lib_es2015_3.getServerProps)();
        this.userPK = Number((0, lib_es2015_2.getAttributeOrThrowError)(this, 'user-pk'));
        this.isNew = this.userPK < 1;
        this.isAdmin = this.serverProps.user !== null && this.serverProps.user.is_admin;
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const userEntity = this.isNew ? (0, entity_1.newEntity)() : yield this.userApis.getUserByPK({ pk: this.userPK });
            if (userEntity === null) {
                alert('fail to load user data.');
                return;
            }
            const filePermissionListPage = yield this.filePermissionApis.listFilePermissions({
                user_pk: this.userPK,
                page_no: 0,
                page_size: 0,
                query: '',
            });
            if (filePermissionListPage === null) {
                alert('fail to load file permission data.');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">PK</div>
      <div>${this.userPK}</div>

      <div class="edit-form-field-name">ID</div>
      <div><input class="id" ${this.isNew ? '' : 'disabled'}/></div>

      <div class="edit-form-field-name">Password</div>
      <div><input type="password" class="password" /></div>
      <div class="edit-form-field-name">ConfirmPassword</div>
      <div><input type="password" class="confirm-password" /></div>

      <div class="edit-form-field-name">Name</div>
      <div><input class="name" /></div>
      
      <div class="edit-form-field-name is-admin">Admin</div>
      <div class="is-admin"><input type="checkbox" class="is-admin" /></div>
    </div>
    <div class="edit-form-footer"><button class="submit-user-edit submit-btn">Submit</button></div>
    <hr class="edit-permissions" />
    <div class="edit-permissions">
        <file-permission-list-editor max-textarea-width="800"></file-permission-list-editor>       
    </div>
    <div class="edit-permissions"><button class="submit-file-permission-list-edit submit-btn">Submit</button></div>
    `;
            this.idInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.id');
            this.idInput.value = userEntity.id;
            this.passwordInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.password');
            this.confirmPasswordInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.confirm-password');
            this.nameInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.name');
            this.nameInput.value = userEntity.name;
            this.isAdminInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.is-admin');
            this.isAdminInput.checked = userEntity.is_admin;
            this.filePermissionListEditor = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'file-permission-list-editor');
            this.filePermissionListEditor.value = filePermissionListPage.items;
            (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.submit-user-edit').addEventListener('click', () => {
                if (this.idInput.value === '') {
                    alert(`put ${Constants.subjectName} id`);
                    return;
                }
                if (this.isNew && this.passwordInput.value === '') {
                    alert(`put ${Constants.subjectName} password`);
                    return;
                }
                if (this.passwordInput.value !== '' && this.passwordInput.value !== this.confirmPasswordInput.value) {
                    alert(`password and confirm password are not same.`);
                    return;
                }
                if (this.nameInput.value === '') {
                    alert(`put ${Constants.subjectName} name`);
                    return;
                }
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = this.isNew ? yield this.userApis.insertUser({
                        pk: 0,
                        id: this.idInput.value,
                        password: this.passwordInput.value,
                        name: this.nameInput.value,
                        is_admin: false, // for security
                    }) : yield this.userApis.updateUser(Object.assign(Object.assign({}, (0, entity_1.newEntity)()), { pk: userEntity.pk, id: this.idInput.value, password: this.passwordInput.value, name: this.nameInput.value, is_admin: this.isAdminInput.checked }));
                    if (apiResp === null) {
                        alert(`failed to ${this.isNew ? 'insert' : 'update'} user!`);
                        return;
                    }
                    alert(`${this.isNew ? 'inserted' : 'updated'} ${Constants.subjectName}, successfully!`);
                    this.router.push(`/web/${Constants.routerPathGroup}/list`);
                }))();
            });
            (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.submit-file-permission-list-edit').addEventListener('click', () => {
                const filePermissionList = this.filePermissionListEditor.value;
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const resp = yield this.filePermissionApis.updateFilePermissionsByUserPK(this.userPK, filePermissionList);
                    if (resp === null) {
                        alert(`fail to update file permission list`);
                        return;
                    }
                    alert(`update file permission list successfully`);
                }))();
            });
            if (this.isNew || !this.isAdmin) {
                this.querySelectorAll('div.is-admin').forEach((elt) => elt.style.setProperty('display', 'none'));
            }
            if (!this.isAdmin) {
                this.querySelectorAll('.edit-permissions').forEach((elt) => elt.style.setProperty('display', 'none'));
            }
        });
    }
}
exports.UserEdit = UserEdit;
const defineUserEdit = (name) => {
    (0, file_permission_list_editor_1.defineFilePermissionListEditor)('file-permission-list-editor');
    (0, lib_es2015_1.defineIfUndefined)(name, UserEdit);
};
exports.defineUserEdit = defineUserEdit;
