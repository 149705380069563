"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.definePlaylistEdit = exports.PlaylistEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/webapp-common/lib-es2015");
const playlist_item_edit_1 = require("./playlist-item-edit");
class PlaylistEdit extends HTMLElement {
    constructor() {
        super();
        this.playlistApis = new apis_1.PlaylistApis();
        this.router = (0, lib_es2015_1.useRouter)();
        this.serverProps = (0, lib_es2015_3.getServerProps)();
        this.playlistPK = Number((0, lib_es2015_2.getAttributeOrThrowError)(this, 'playlist-pk'));
        this.isNew = this.playlistPK < 1;
        this.fetchData = () => __awaiter(this, void 0, void 0, function* () {
            const playlistEntity = yield this.playlistApis.getPlaylistByPK({ pk: this.playlistPK });
            if (playlistEntity === null) {
                alert('fail to load playlist data.');
                return;
            }
            this.playlistNameInput.value = playlistEntity.name;
            this.playlistItemEdit.value = playlistEntity.items;
            this.userPKSpan.innerHTML = `${playlistEntity.user_pk}`;
        });
        this.style.setProperty('display', 'block');
        this.innerHTML = `
    <div class="edit-form">
      <div class="playlist-edit-header">
        <div>
          Playlist Name: <input class="playlist-name"> (UserPK: <span class="user-pk"></span>)
        </div>
        <button class="submit">${this.isNew ? 'Create' : 'Update'}</button>
      </div>
      <playlist-item-edit></playlist-item-edit>
    </div>
    `;
        this.playlistNameInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.playlist-name');
        this.playlistItemEdit = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'playlist-item-edit');
        this.userPKSpan = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'span.user-pk');
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.submit').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = this.isNew ? yield this.playlistApis.insertPlaylist({
                    user_pk: this.serverProps.user.pk,
                    name: this.playlistNameInput.value,
                    items: this.playlistItemEdit.value,
                }) : yield this.playlistApis.updatePlaylist({
                    pk: this.playlistPK,
                    user_pk: this.serverProps.user.pk,
                    name: this.playlistNameInput.value,
                    items: this.playlistItemEdit.value,
                });
                if (resp === null) {
                    alert(`fail to ${this.isNew ? 'create' : 'update'} playlist.`);
                    return;
                }
                alert(`${this.isNew ? 'create' : 'update'} playlist successfully`);
                yield this.fetchData();
            }))();
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isNew) {
                yield this.fetchData();
            }
            else {
                this.playlistNameInput.value = '';
                this.playlistItemEdit.value = [];
                this.userPKSpan.innerHTML = `${this.serverProps.user.pk}`;
            }
        });
    }
}
exports.PlaylistEdit = PlaylistEdit;
const definePlaylistEdit = (name) => {
    (0, playlist_item_edit_1.definePlaylistItemEdit)('playlist-item-edit');
    (0, lib_es2015_1.defineIfUndefined)(name, PlaylistEdit);
};
exports.definePlaylistEdit = definePlaylistEdit;
