"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newEntity = newEntity;
function newEntity() {
    return {
        pk: 0,
        id: '',
        password: '',
        name: '',
        is_admin: false,
    };
}
