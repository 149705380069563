"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainFrameNavigator = exports.MainFrameNavigator = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/searchable-tree-menu/lib-es2015");
const FilePermissionConstants = require("../file-permission/constants");
const UserConstants = require("../user/constants");
const TaskConstants = require("../task/constants");
const PlaylistConstants = require("../playlist/constants");
class MainFrameNavigator extends HTMLElement {
    constructor() {
        super();
        this.router = (0, lib_es2015_1.useRouter)();
        const isMobile = window.matchMedia(`(max-width: 600px)`).matches;
        const menuCfg = {
            name: ``,
            children: [
                {
                    name: `Files`,
                    onMenuClick: () => {
                        this.router.push(`/web/${FilePermissionConstants.routerPathGroup}/entrances`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Playlists`,
                    onMenuClick: () => {
                        this.router.push(`/web/${PlaylistConstants.routerPathGroup}/list`); // %2F is slash(/)
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Users`,
                    onMenuClick: () => {
                        this.router.push(`/web/${UserConstants.routerPathGroup}/list`); // %2F is slash(/)
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Tasks`,
                    onMenuClick: () => {
                        this.router.push(`/web/${TaskConstants.routerPathGroup}/list`); // %2F is slash(/)
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
            ],
            isFold: false,
        };
        const treeMenu = new lib_es2015_2.MenuNode(menuCfg, 0);
        this.appendChild(treeMenu);
        if (isMobile)
            this.style.setProperty('display', 'none');
    }
}
exports.MainFrameNavigator = MainFrameNavigator;
const defineMainFrameNavigator = (name) => {
    (0, lib_es2015_2.define)(`searchable-tree-menu`);
    (0, lib_es2015_1.defineIfUndefined)(name, MainFrameNavigator);
};
exports.defineMainFrameNavigator = defineMainFrameNavigator;
