"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineRename = exports.Rename = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const common_1 = require("../common");
const apis_1 = require("../apis");
const common_2 = require("./common");
class Rename extends HTMLElement {
    constructor() {
        super();
        this.fileManagerApis = new apis_1.FileManagerApis();
        this.showPopup = (dirEntry, path) => {
            this.dirEntry = dirEntry;
            this.path = path;
            this.nameEditor.value = this.dirEntry.name;
            this.style.setProperty('display', 'flex');
        };
        this.hidePopup = () => {
            this.dirEntry = undefined;
            this.path = undefined;
            this.nameEditor.value = '';
            this.style.setProperty('display', 'none');
        };
        this.innerHTML = `<div class="popup">
      <div class="title">Rename</div>
      <textarea class="name-editor" rows="10"></textarea>
      <div class="button-container">  
        <button class="submit">Submit</button><button class="cancel">Cancel</button>
      </div>
    </div>`;
        this.popup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.popup');
        (0, common_2.setPopupBackgroundStyle)(this);
        (0, common_2.setPopupStyle)(this.popup);
        this.popup.style.setProperty('flex-direction', 'column');
        this.popup.style.setProperty('padding', '0.5rem');
        this.popup.style.setProperty('box-sizing', 'border-box');
        this.popup.style.setProperty('align-items', '');
        this.addEventListener('click', () => {
            this.hidePopup();
        });
        this.popup.addEventListener('click', (e) => {
            // ignore the click event on 'this' when user clicks the 'this.popup'.
            e.stopPropagation();
        });
        this.nameEditor = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'textarea.name-editor');
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.submit').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                if (this.dirEntry === undefined) {
                    alert('dirEntry is undefined');
                    return;
                }
                if (this.path === undefined) {
                    alert('path is undefined');
                    return;
                }
                const currentPath = (0, common_1.createFullPath)(this.path, this.dirEntry);
                const neoPath = (0, common_1.createFullPath)(this.path, Object.assign(Object.assign({}, this.dirEntry), { name: this.nameEditor.value }));
                console.log(`Rename ${currentPath} to ${neoPath}`);
                const resp = yield this.fileManagerApis.renameFile(currentPath, neoPath);
                if (resp === null) {
                    alert('fail to rename item!');
                    return;
                }
                alert('Renamed successfully!');
                this.hidePopup();
                this.dispatchEvent(new CustomEvent('rename-success'));
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.cancel').addEventListener('click', () => {
            this.hidePopup();
        });
    }
}
exports.Rename = Rename;
const defineRename = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, Rename);
};
exports.defineRename = defineRename;
