"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isServiceFileType = exports.ServiceFileExtensions = exports.PreviewCreatable = exports.WebCompatibleCreatable = exports.ServiceFileType = void 0;
var ServiceFileType;
(function (ServiceFileType) {
    ServiceFileType["Unknown"] = "Unknown";
    ServiceFileType["Video"] = "Video";
    ServiceFileType["Audio"] = "Audio";
    ServiceFileType["Image"] = "Image";
    ServiceFileType["Text"] = "Text";
    ServiceFileType["Manga"] = "Manga";
    ServiceFileType["Subtitle"] = "Subtitle";
    ServiceFileType["PDF"] = "PDF";
    ServiceFileType["SMI"] = "SMI";
    ServiceFileType["SevenZip"] = "SevenZip";
})(ServiceFileType || (exports.ServiceFileType = ServiceFileType = {}));
exports.WebCompatibleCreatable = [ServiceFileType.PDF, ServiceFileType.Video, ServiceFileType.SMI, ServiceFileType.SevenZip];
exports.PreviewCreatable = [ServiceFileType.PDF, ServiceFileType.Video, ServiceFileType.Manga, ServiceFileType.Image];
exports.ServiceFileExtensions = {
    Unknown: [],
    Video: ['mp4', 'mkv', 'webm', 'flv', 'ogv', 'ogg', 'mpeg', 'mpg', 'avi', 'asf', 'swf'],
    Audio: ['mp3', 'm4a', 'oga'],
    Image: ['jpg', 'jpeg', 'png', 'tiff', 'webp', 'gif', 'svg', 'bmp'],
    Text: ['c', 'cpp', 'css', 'docker', 'go', 'html', 'java', 'js', 'json', 'ko', 'txt', 'latex', 'md', 'php', 'pl', 'proto', 'py', 'rs', 'sh', 'ts', 'tex', 'yaml', 'yml', 'xml'],
    Manga: ['zip'],
    Subtitle: ['vtt'],
    PDF: ['pdf'],
    SMI: ['smi'],
    SevenZip: ['7z'],
};
const isServiceFileType = (ext) => {
    for (const key in ServiceFileType) {
        const svcFileType = ServiceFileType[key];
        if (exports.ServiceFileExtensions[svcFileType].includes(ext)) {
            return svcFileType;
        }
    }
    return ServiceFileType.Unknown;
};
exports.isServiceFileType = isServiceFileType;
