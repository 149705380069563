"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainFrameHeader = exports.MainFrameHeader = void 0;
const lib_es2015_1 = require("@hodol/webapp-common/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/web-component-helper/lib-es2015");
const UserConstant = require("../user/constants");
class MainFrameHeader extends HTMLElement {
    constructor() {
        super();
        this.router = (0, lib_es2015_3.useRouter)();
        this.serverProps = (0, lib_es2015_1.getServerProps)();
        this.moveToLoginPage = () => {
            this.router.push(`/web/${UserConstant.routerPathGroup}/login`);
        };
        this.moveToSignUpPage = () => {
            this.router.push(`/web/${UserConstant.routerPathGroup}/edit/0`);
        };
        this.moveToUserEditPage = (userPK) => {
            this.router.push(`/web/${UserConstant.routerPathGroup}/edit/${userPK}`);
        };
        this.logout = () => __awaiter(this, void 0, void 0, function* () {
            const ans = confirm('Are you sure to logout?');
            if (ans) {
                this.router.push(`/web/${UserConstant.routerPathGroup}/logout`);
            }
        });
        this.innerHTML = `
      <div>
        <button class="header-btn menu-btn">Menu</button>
        <a href="/web"><button class="header-btn">Home</button></a>
      </div>
      <div class="member-control placeholder"></div>`;
        const menuBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.menu-btn');
        menuBtn.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('click-menu-btn', {
                bubbles: true,
                cancelable: true,
            }));
        });
        const isLogin = this.serverProps.user !== null;
        if (isLogin) {
            const userEntity = this.serverProps.user;
            const memberControl = (0, lib_es2015_2.buildNodeByOuterHTML)(`
        <div class="member-control direct-login">
          <button class="header-btn user-edit">${userEntity.name}</button>
          <button class="header-btn logout">Logout</button>
        </div>
      `);
            this.replaceChild(memberControl, (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `div.member-control`));
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.logout`).addEventListener('click', () => {
                void this.logout();
            });
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.user-edit`).addEventListener('click', () => {
                this.moveToUserEditPage(userEntity.pk);
            });
        }
        else {
            const memberControl = (0, lib_es2015_2.buildNodeByOuterHTML)(`
        <div class="member-control logout">
          <button class="header-btn login">Login</button>
          <button class="header-btn sign-up">Sign up</button>
        </div>
      `);
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.login`).addEventListener('click', () => this.moveToLoginPage());
            (0, lib_es2015_2.getQuerySelectOrThrowError)(memberControl, `button.sign-up`).addEventListener('click', () => this.moveToSignUpPage());
            this.replaceChild(memberControl, (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `div.member-control`));
        }
    }
}
exports.MainFrameHeader = MainFrameHeader;
const defineMainFrameHeader = (name) => (0, lib_es2015_3.defineIfUndefined)(name, MainFrameHeader);
exports.defineMainFrameHeader = defineMainFrameHeader;
