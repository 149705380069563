"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineFileList = exports.FileList = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const common_1 = require("./common");
const dir_entry_list_1 = require("./dir-entry-list");
const service_file_type_1 = require("./service-file-type");
const Popup = require("./popup");
class FileList extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.FileManagerApis();
        this.fileOperation = 'none';
        this.selectedPaths = [];
        this.fetchItems = () => __awaiter(this, void 0, void 0, function* () {
            const apiResp = yield this.apis.list({ path: this.pathInput.value, token: this.token });
            if (apiResp === null) {
                return;
            }
            apiResp.sort((a, b) => {
                if (a.is_dir && !b.is_dir)
                    return -1;
                else if (!a.is_dir && b.is_dir)
                    return 1;
                return a.modified_at > b.modified_at ? -1 : 1;
            });
            const contents = apiResp.map(item => (Object.assign({}, item)));
            this.table.setData(this.pathInput.value, contents);
        });
        this.getSelectedItemPaths = () => {
            return this.table.getSelectedDirEntries().map(dirEntry => `${this.pathInput.value}/${dirEntry.name}`);
        };
        this.innerHTML = `
    <div class="function-button-container">
      <button class="open-sharing-popup">Share</button>
      <button class="copy-items">Copy</button>
      <button class="move-items">Move</button>
      <button class="paste-items">Paste</button>
      <button class="delete-items">Delete</button>
      <button class="create-web-compatibles">Create Web Compatibles</button>
      <button class="create-previews">Create Previews</button>
      <button class="open-youtube-download-popup">Youtube-dl</button>
      <button class="open-add-to-playlist-popup">Add to Playlist</button>
      <form class="file-upload" enctype="multipart/form-data">
        <input type="file" class="file-upload-contents" name="files" multiple hidden>
        <input type="text" class="file-upload-destination" name="destination" hidden>
      </form>
    </div>
    <div>
      <input type="text" class="path" value="/" />
    </div>
    <dir-entry-list></dir-entry-list>
    <div class="tools">
      <manage-dir-entry-popup></manage-dir-entry-popup>
      <alert-popup></alert-popup>
      <sharing-popup></sharing-popup>
      <progress-popup></progress-popup>
      <rename-popup></rename-popup>
      <youtube-download-popup></youtube-download-popup>
      <upload-video-cover-popup></upload-video-cover-popup>
      <create-video-cover-by-scene-popup></create-video-cover-by-scene-popup>
      <extract-audio-popup></extract-audio-popup>
      <extract-gif-popup></extract-gif-popup>
      <add-to-playlist-popup></add-to-playlist-popup>
    </div>
    `;
        this.pathInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.path');
        this.table = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'dir-entry-list');
        this.pathInput.addEventListener('keydown', e => {
            if (e.key === 'Enter') {
                const url = new URL(window.location.href);
                url.searchParams.set('path', this.pathInput.value);
                window.history.pushState({}, '', url);
                void (() => __awaiter(this, void 0, void 0, function* () { return yield this.fetchItems(); }))();
            }
        });
        this.table.addEventListener('click-dir-entry', (e) => {
            const entity = e.detail;
            if (entity.is_dir) {
                this.pathInput.value = this.pathInput.value === '/' ? `/${entity.name}` : entity.name === '..' ? `/${this.pathInput.value.split('/').slice(1, -1).join('/')}` : `${this.pathInput.value}/${entity.name}`;
                const url = new URL(window.location.href);
                url.searchParams.set('path', this.pathInput.value);
                window.history.pushState({}, '', url);
                void (() => __awaiter(this, void 0, void 0, function* () { return yield this.fetchItems(); }))();
                return;
            }
            const viewerUrl = (0, common_1.getViewerUrl)(entity, this.pathInput.value, this.token);
            if (viewerUrl === '') {
                return;
            }
            window.open(viewerUrl, '_blank');
        });
        this.table.addEventListener('click-manage-dir-entry-button', (e) => {
            const evtDetail = e.detail;
            this.manageDirEntryPopup.showPopup(evtDetail.dirEntry, this.pathInput.value, evtDetail.clientX, evtDetail.clientY);
        });
        this.manageDirEntryPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'manage-dir-entry-popup');
        this.alertPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'alert-popup');
        this.sharingPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'sharing-popup');
        this.progressPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'progress-popup');
        this.renamePopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'rename-popup');
        this.renamePopup.addEventListener('rename-success', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                yield this.fetchItems();
            }))();
        });
        this.youtubeDownloadPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'youtube-download-popup');
        this.uploadVideoCoverPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'upload-video-cover-popup');
        this.createVideoCoverByScenePopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'create-video-cover-by-scene-popup');
        this.extractAudioPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'extract-audio-popup');
        this.extractGifPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'extract-gif-popup');
        this.addToPlaylistPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'add-to-playlist-popup');
        this.manageDirEntryPopup.addEventListener('share-button-click', (e) => {
            this.sharingPopup.showPopup(e.detail.dirEntry, e.detail.path);
        });
        this.manageDirEntryPopup.addEventListener('download-button-click', (e) => {
            const fileFullPath = (0, common_1.createFullPath)(e.detail.path, e.detail.dirEntry);
            const tempAnchor = (0, lib_es2015_2.buildNodeByOuterHTML)(`<a href="/contents${fileFullPath}?token=${this.token}" target='_blank' download="${e.detail.dirEntry.name}"></a>`);
            tempAnchor.click();
        });
        this.manageDirEntryPopup.addEventListener('copy-button-click', (e) => {
            const itemFullPath = (0, common_1.createFullPath)(e.detail.path, e.detail.dirEntry);
            this.fileOperation = 'copy';
            this.selectedPaths = [itemFullPath];
            alert('The item is ready to copy, go to the destination and paste!');
        });
        this.manageDirEntryPopup.addEventListener('move-button-click', (e) => {
            const itemFullPath = (0, common_1.createFullPath)(e.detail.path, e.detail.dirEntry);
            this.fileOperation = 'move';
            this.selectedPaths = [itemFullPath];
            alert('The item is ready to move, go to the destination and paste!');
        });
        this.manageDirEntryPopup.addEventListener('delete-button-click', (e) => {
            const itemFullPath = (0, common_1.createFullPath)(e.detail.path, e.detail.dirEntry);
            const ans = confirm(`The item ${e.detail.dirEntry.name} will be deleted, are you sure?`);
            if (!ans) {
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.apis.deleteFiles({ sources: [itemFullPath], destination: '' });
                if (resp === null) {
                    alert('fail to delete!');
                    return;
                }
                alert('Requested deleting item successfully!');
                yield this.fetchItems();
            }))();
        });
        this.manageDirEntryPopup.addEventListener('rename-button-click', (e) => {
            this.renamePopup.showPopup(e.detail.dirEntry, e.detail.path);
        });
        this.manageDirEntryPopup.addEventListener('create-web-compatible-click', (e) => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.apis.createWebCompatibles([(0, common_1.createFullPath)(e.detail.path, e.detail.dirEntry)]);
                if (resp === null) {
                    alert('fail to request to create web compatible');
                    return;
                }
                alert('requested to create web compatible successfully');
            }))();
        });
        this.manageDirEntryPopup.addEventListener('create-preview-click', (e) => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.apis.createPreviews([(0, common_1.createFullPath)(e.detail.path, e.detail.dirEntry)]);
                if (resp === null) {
                    alert('fail to request to create preview');
                    return;
                }
                alert('requested to create preview successfully');
            }))();
        });
        this.manageDirEntryPopup.addEventListener('upload-cover-click', (e) => {
            this.uploadVideoCoverPopup.showPopup(e.detail.dirEntry, e.detail.path);
        });
        this.manageDirEntryPopup.addEventListener('set-cover-by-scene-click', (e) => {
            this.createVideoCoverByScenePopup.showPopup(e.detail.dirEntry, e.detail.path);
        });
        this.manageDirEntryPopup.addEventListener('extract-audio-click', (e) => {
            this.extractAudioPopup.showPopup(e.detail.dirEntry, e.detail.path);
        });
        this.manageDirEntryPopup.addEventListener('extract-gif-click', (e) => {
            this.extractGifPopup.showPopup(e.detail.dirEntry, e.detail.path);
        });
        this.manageDirEntryPopup.addEventListener('add-to-playlist-click', (e) => {
            this.addToPlaylistPopup.showPopup([`${e.detail.path}/${e.detail.dirEntry.name}`]);
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.function-button-container button.open-sharing-popup').addEventListener('click', () => {
            this.sharingPopup.showPopup({ is_dir: true, name: '', mode: '', size: 0, modified_at: '' }, this.pathInput.value);
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.copy-items').addEventListener('click', () => {
            const selectedItemPaths = this.getSelectedItemPaths();
            if (selectedItemPaths.length < 1) {
                alert(`you selected nothing!`);
                return;
            }
            this.selectedPaths = selectedItemPaths;
            this.fileOperation = 'copy';
            alert('The items are ready to copy, go to the destination and paste!');
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.move-items').addEventListener('click', () => {
            const selectedItemPaths = this.getSelectedItemPaths();
            if (selectedItemPaths.length < 1) {
                alert(`you selected nothing!`);
                return;
            }
            this.selectedPaths = selectedItemPaths;
            this.fileOperation = 'move';
            alert('The items are ready to move, go to the destination and paste!');
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.paste-items').addEventListener('click', () => {
            if (this.selectedPaths.length < 1) {
                alert(`nothing to do`);
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                if (this.fileOperation === 'copy') {
                    const resp = yield this.apis.copyFiles({ sources: this.selectedPaths, destination: this.pathInput.value });
                    if (resp === null) {
                        this.fileOperation = 'none';
                        this.selectedPaths = [];
                        alert('fail to copy!');
                        return;
                    }
                    alert('Requested copying items successfully!');
                }
                else if (this.fileOperation === 'move') {
                    const resp = yield this.apis.moveFiles({ sources: this.selectedPaths, destination: this.pathInput.value });
                    if (resp === null) {
                        this.fileOperation = 'none';
                        this.selectedPaths = [];
                        alert('fail to move!');
                        return;
                    }
                    alert('Requested moving items successfully!');
                }
                else {
                    alert(`Unsupported operation: ${this.fileOperation}`);
                }
                this.fileOperation = 'none';
                this.selectedPaths = [];
                yield this.fetchItems();
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.delete-items').addEventListener('click', () => {
            const selectedItemPaths = this.getSelectedItemPaths();
            if (selectedItemPaths.length < 1) {
                alert(`you selected nothing!`);
                return;
            }
            const ans = confirm(`The items:\n ${selectedItemPaths.join('\n')}\nwill be deleted, are you sure?`);
            if (!ans) {
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.apis.deleteFiles({ sources: selectedItemPaths, destination: '' });
                if (resp === null) {
                    alert('fail to delete!');
                    return;
                }
                alert('Requested deleting items successfully!');
                yield this.fetchItems();
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.create-web-compatibles').addEventListener('click', () => {
            const selectedItemPaths = this.getSelectedItemPaths();
            if (selectedItemPaths.length < 1) {
                alert(`you selected nothing!`);
                return;
            }
            const unsupportedFiles = selectedItemPaths.filter(itemPath => !service_file_type_1.WebCompatibleCreatable.includes((0, service_file_type_1.isServiceFileType)(itemPath.substring(itemPath.lastIndexOf('.') + 1).toLowerCase())));
            if (unsupportedFiles.length > 0) {
                alert(`Unsupported files are included in selected files`);
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.apis.createWebCompatibles(selectedItemPaths);
                if (resp === null) {
                    alert('fail to create web compatible files!');
                    return;
                }
                alert('Requested creating web compatible files successfully!');
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.create-previews').addEventListener('click', () => {
            const selectedItemPaths = this.getSelectedItemPaths();
            if (selectedItemPaths.length < 1) {
                alert(`you selected nothing!`);
                return;
            }
            const unsupportedFiles = selectedItemPaths.filter(itemPath => !service_file_type_1.PreviewCreatable.includes((0, service_file_type_1.isServiceFileType)(itemPath.substring(itemPath.lastIndexOf('.') + 1).toLowerCase())));
            if (unsupportedFiles.length > 0) {
                alert(`Unsupported files are included in selected files`);
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.apis.createPreviews(selectedItemPaths);
                if (resp === null) {
                    alert('fail to create previews!');
                    return;
                }
                alert('Requested creating previews successfully!');
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.open-youtube-download-popup').addEventListener('click', () => {
            this.youtubeDownloadPopup.showPopup(this.pathInput.value);
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.open-add-to-playlist-popup').addEventListener('click', () => {
            const selectedItemPaths = this.getSelectedItemPaths();
            if (selectedItemPaths.length < 1) {
                alert(`you selected nothing!`);
                return;
            }
            const unsupportedFiles = selectedItemPaths.filter(itemPath => {
                const svcFileType = (0, service_file_type_1.isServiceFileType)(itemPath.substring(itemPath.lastIndexOf('.') + 1).toLowerCase());
                return ![service_file_type_1.ServiceFileType.Video, service_file_type_1.ServiceFileType.Audio, service_file_type_1.ServiceFileType.Image].includes(svcFileType);
            });
            if (unsupportedFiles.length > 0) {
                alert(`Some files can not be added to playlist: ${unsupportedFiles.join(', ')}`);
                return;
            }
            this.addToPlaylistPopup.showPopup(selectedItemPaths);
        });
        this.fileUploadForm = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'form.file-upload');
        this.fileUploadInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.file-upload-contents');
        this.fileUploadDestInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.file-upload-destination');
        this.addEventListener('dragenter', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.style.setProperty('box-shadow', 'inset 1px -1px 12px 8px #ffda00');
        });
        this.addEventListener('dragover', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.style.setProperty('box-shadow', 'inset 1px -1px 12px 8px #ffda00');
        });
        this.addEventListener('dragleave', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.style.setProperty('box-shadow', 'none');
        });
        this.addEventListener('drop', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.style.setProperty('box-shadow', 'none');
            this.fileUploadDestInput.value = this.pathInput.value;
            if (e.dataTransfer === null) {
                alert(`dataTransfer is null`);
                return;
            }
            const files = e.dataTransfer.files;
            if (files.length < 1) {
                alert(`No files to upload`);
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                for (let i = 0; i < files.length; i++) {
                    try {
                        yield files[i].slice(0, 1).arrayBuffer();
                    }
                    catch (_a) {
                        alert(`A directory (${files[i].name}) contained in dropped items, uploading directory still not supported!`);
                        return;
                    }
                }
                const filePaths = [];
                for (let i = 0; i < files.length; i++) {
                    filePaths.push(`${this.fileUploadDestInput.value}/${files[i].name}`);
                }
                const checkResp = yield this.apis.isAtLeastOneFileExist(filePaths);
                if (checkResp === null) {
                    alert('fail to check file existence!');
                    return;
                }
                if (checkResp) {
                    alert('one of files already exist.');
                    return;
                }
                this.fileUploadInput.files = files;
                const formData = new FormData(this.fileUploadForm);
                const uploadResp = yield this.apis.uploadFiles(formData, {
                    loadstart: () => {
                        this.progressPopup.showPopup();
                    },
                    progress: (e) => {
                        if (e.lengthComputable) {
                            const progress = Math.floor((e.loaded / e.total) * 100);
                            this.progressPopup.setPopupContents(`uploaded: ${progress}%`, progress);
                        }
                    },
                    loadend: () => {
                        this.progressPopup.hidePopup();
                    },
                });
                if (uploadResp === null) {
                    alert('fail to upload!');
                    return;
                }
                alert('upload success!');
                yield this.fetchItems();
            }))();
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const path = (0, lib_es2015_2.getAttributeOrThrowError)(this, 'path');
            this.token = (0, lib_es2015_2.getAttributeOrDefault)(this, 'token', '');
            if (path === null || path === '') {
                const url = new URL(window.location.href);
                url.searchParams.set('path', '/');
                window.history.pushState({}, '', url);
                this.pathInput.value = '/';
            }
            else {
                this.pathInput.value = path;
            }
            yield this.fetchItems();
        });
    }
}
exports.FileList = FileList;
const defineFileList = (name) => {
    (0, dir_entry_list_1.defineDirEntryList)('dir-entry-list');
    Popup.defineManageDirEntry('manage-dir-entry-popup');
    Popup.defineAlert('alert-popup');
    Popup.defineSharing('sharing-popup');
    Popup.defineProgress('progress-popup');
    Popup.defineRename('rename-popup');
    Popup.defineYoutubeDownload('youtube-download-popup');
    Popup.defineUploadVideoCover('upload-video-cover-popup');
    Popup.defineCreateVideoCoverByScene('create-video-cover-by-scene-popup');
    Popup.defineExtractAudio('extract-audio-popup');
    Popup.defineExtractGif('extract-gif-popup');
    Popup.defineAddToPlaylist('add-to-playlist-popup');
    (0, lib_es2015_1.defineIfUndefined)(name, FileList);
};
exports.defineFileList = defineFileList;
