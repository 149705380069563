"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.definePlaylistItemsPopup = exports.PlaylistItemsPopup = exports.EVENT_SELECT_ITEM = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
exports.EVENT_SELECT_ITEM = 'select-item';
class PlaylistItemsPopup extends HTMLElement {
    constructor() {
        super();
        this.allCheckboxes = [];
        this.playListItems = [];
        this.repeat = 'none';
        this.createTableRowByPlaylistItem = (playlistItem, index) => {
            const tr = document.createElement('TR');
            tr.setAttribute('data-index', `${index}`);
            tr.setAttribute('data-playlist-item-pk', `${playlistItem.pk}`);
            tr.classList.add('playlist-item');
            tr.innerHTML = `
      <td class="checkbox playlist-item">
        <input class="checkbox playlist-item" type="checkbox" data-index='${index}'>
      </td>
      <td class="file-name playlist-item">
        <div>${playlistItem.file_path.substring(playlistItem.file_path.lastIndexOf('/') + 1)}</div>
      </td>`;
            const checkbox = (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, 'input.checkbox.playlist-item');
            checkbox.addEventListener('change', (e) => {
                e.stopPropagation();
                let isAllChecked = true;
                this.allCheckboxes.forEach((checkbox) => {
                    if (checkbox.checked)
                        return;
                    isAllChecked = false;
                });
                this.globalCheckbox.checked = isAllChecked;
            });
            this.allCheckboxes.push(checkbox);
            tr.querySelectorAll('td:not(.checkbox)').forEach(td => {
                td.addEventListener('click', () => {
                    this.dispatchEvent(new CustomEvent(exports.EVENT_SELECT_ITEM, { detail: playlistItem }));
                    this.setCurrentPlaying(playlistItem.pk);
                });
            });
            return tr;
        };
        this.setData = (playlistItems) => {
            this.reset();
            this.playListItems = playlistItems;
            this.playListItems.forEach((item, i) => {
                const tr = this.createTableRowByPlaylistItem(item, i);
                this.tableBody.appendChild(tr);
            });
        };
        this.setRepeat = (repeat) => {
            this.repeat = repeat;
        };
        this.setCurrentPlaying = (pk) => {
            this.tableBody.querySelectorAll('tr').forEach(tr => {
                if ((0, lib_es2015_1.getAttributeOrDefault)(tr, 'data-playlist-item-pk', '-1') === `${pk}`) {
                    tr.classList.add('current-playing');
                }
                else {
                    tr.classList.remove('current-playing');
                }
            });
        };
        this.playNext = () => {
            const currentPlayingTr = this.tableBody.querySelector(`tr.current-playing`);
            if (currentPlayingTr === null) {
                const firstTr = this.tableBody.firstChild;
                if (firstTr === null) {
                    alert(`empty playlist`);
                    return;
                }
                const playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(firstTr, 'data-index'))];
                this.dispatchEvent(new CustomEvent(exports.EVENT_SELECT_ITEM, { detail: playlistItem }));
                this.setCurrentPlaying(playlistItem.pk);
                return;
            }
            const nextTr = currentPlayingTr.nextSibling;
            let playlistItem = undefined;
            if (this.repeat === 'none') {
                if (nextTr === null) {
                    alert('no next item to play');
                    return;
                }
                else {
                    playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(nextTr, 'data-index'))];
                }
            }
            else if (this.repeat === 'repeat-all') {
                if (nextTr === null) {
                    const firstTr = this.tableBody.firstChild;
                    if (firstTr === null) {
                        alert(`empty playlist`);
                        return;
                    }
                    playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(firstTr, 'data-index'))];
                }
                else {
                    playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(nextTr, 'data-index'))];
                }
            }
            else if (this.repeat === 'repeat-one') {
                playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(currentPlayingTr, 'data-index'))];
            }
            else if (this.repeat === 'random') {
                if (this.tableBody.children.length < 1) {
                    alert(`empty playlist`);
                    return;
                }
                const randomTr = this.tableBody.children[Math.floor(Math.random() * 10000) % this.tableBody.children.length];
                playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(randomTr, 'data-index'))];
            }
            else {
                throw Error(`wrong repeat method: ${this.repeat}`);
            }
            this.dispatchEvent(new CustomEvent(exports.EVENT_SELECT_ITEM, { detail: playlistItem }));
            this.setCurrentPlaying(playlistItem.pk);
        };
        this.playPrev = () => {
            const currentPlayingTr = this.tableBody.querySelector(`tr.current-playing`);
            if (currentPlayingTr === null) {
                const lastTr = this.tableBody.lastChild;
                if (lastTr === null) {
                    alert(`empty playlist`);
                    return;
                }
                const playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(lastTr, 'data-index'))];
                this.dispatchEvent(new CustomEvent(exports.EVENT_SELECT_ITEM, { detail: playlistItem }));
                this.setCurrentPlaying(playlistItem.pk);
                return;
            }
            const prevTr = currentPlayingTr.previousSibling;
            let playlistItem = undefined;
            if (this.repeat === 'none') {
                if (prevTr === null) {
                    alert('no previous item to play');
                    return;
                }
                else {
                    playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(prevTr, 'data-index'))];
                }
            }
            else if (this.repeat === 'repeat-all') {
                if (prevTr === null) {
                    const lastTr = this.tableBody.lastChild;
                    if (lastTr === null) {
                        alert(`empty playlist`);
                        return;
                    }
                    playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(lastTr, 'data-index'))];
                }
                else {
                    playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(prevTr, 'data-index'))];
                }
            }
            else if (this.repeat === 'repeat-one') {
                playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(currentPlayingTr, 'data-index'))];
            }
            else if (this.repeat === 'random') {
                if (this.tableBody.children.length < 1) {
                    alert(`empty playlist`);
                    return;
                }
                const randomTr = this.tableBody.children[Math.floor(Math.random() * 10000) % this.tableBody.children.length];
                playlistItem = this.playListItems[Number((0, lib_es2015_1.getAttributeOrThrowError)(randomTr, 'data-index'))];
            }
            else {
                throw Error(`wrong repeat method: ${this.repeat}`);
            }
            this.dispatchEvent(new CustomEvent(exports.EVENT_SELECT_ITEM, { detail: playlistItem }));
            this.setCurrentPlaying(playlistItem.pk);
        };
        this.reset = () => {
            this.playListItems = [];
            this.allCheckboxes = [];
            this.tableBody.innerHTML = '';
        };
        this.getSelectedPlaylistItems = () => {
            const checkIndex = this.allCheckboxes
                .filter((checkbox) => checkbox.checked)
                .map((checkbox) => Number(checkbox.dataset.index));
            return this.playListItems.filter((item, i) => checkIndex.includes(i));
        };
        this.style.setProperty('display', 'block');
        this.style.setProperty('overflow', 'auto');
        this.innerHTML = `
    <table style='width: 100%;'>
      <thead style='position: sticky; top:0;'>
        <tr>
          <td class="checkbox header">
            <input class="checkbox header" type="checkbox">
          </td>
          <td class="file-name header">file name</td>
      </thead>
      <tbody></tbody>
    </table>
    `;
        this.table = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'table');
        this.tableBody = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.table, 'tbody');
        this.globalCheckbox = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.checkbox.header');
        this.globalCheckbox.addEventListener('change', () => {
            this.allCheckboxes.forEach((checkbox) => checkbox.checked = this.globalCheckbox.checked);
        });
    }
}
exports.PlaylistItemsPopup = PlaylistItemsPopup;
const definePlaylistItemsPopup = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, PlaylistItemsPopup);
};
exports.definePlaylistItemsPopup = definePlaylistItemsPopup;
