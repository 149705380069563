"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.define = exports.MangaViewer = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
// eslint-disable-next-line @typescript-eslint/no-require-imports
const JSZip = require("jszip");
class MangaViewer extends HTMLElement {
    constructor() {
        super();
        this.innerHTML = `
      <div><select class="file-selector" size="200"></select></div>
      <div class="image-viewer-container">
        <div class="image-viewer"></div>
        <div class="image-viewer-controller">
          <button class="previous-image">Prev</button>
          <button class="next-image">Next</button>
        </div>
      </div>`;
        this.fileSelector = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'select.file-selector');
        this.fileSelector.addEventListener('change', () => {
            const targetFileName = this.fileSelector.value;
            if (this.zip === undefined) {
                alert('the zip file is not loaded');
                return;
            }
            const targetFile = this.zip.file(targetFileName);
            if (!targetFile) {
                alert(`${targetFileName} not found in ZIP file!`);
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                const imageBlob = yield targetFile.async('blob');
                // Create a URL for the image blob
                const imageUrl = URL.createObjectURL(imageBlob);
                this.imageViewer.innerHTML = '';
                const imageElt = (0, lib_es2015_2.buildNodeByOuterHTML)(`<img src=${imageUrl} alt="${targetFileName}" />`);
                this.imageViewer.appendChild(imageElt);
            }))();
        });
        this.imageViewer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.image-viewer');
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.previous-image').addEventListener('click', () => {
            const currentIdx = this.fileSelector.selectedIndex;
            this.fileSelector.selectedIndex = currentIdx === 0 ? 0 : currentIdx - 1;
            this.fileSelector.dispatchEvent(new Event('change'));
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.next-image').addEventListener('click', () => {
            const currentIdx = this.fileSelector.selectedIndex;
            const maxIdx = this.fileSelector.children.length - 1;
            this.fileSelector.selectedIndex = currentIdx === maxIdx ? maxIdx : currentIdx + 1;
            this.fileSelector.dispatchEvent(new Event('change'));
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const zipUrl = (0, lib_es2015_2.getAttributeOrThrowError)(this, 'file');
            const zipResponse = yield fetch(zipUrl);
            const zipBlob = yield zipResponse.blob();
            // Load the ZIP file using JSZip
            this.zip = yield JSZip.loadAsync(zipBlob);
            Object.keys(this.zip.files)
                .filter(fileName => !fileName.endsWith('/'))
                .sort((a, b) => a < b ? -1 : 1)
                .forEach(fileName => this.fileSelector.appendChild((0, lib_es2015_2.buildNodeByOuterHTML)(`<option value="${fileName}">${fileName}</option>`)));
        });
    }
}
exports.MangaViewer = MangaViewer;
const define = (name) => (0, lib_es2015_1.defineIfUndefined)(name, MangaViewer);
exports.define = define;
