"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newFilePermission = newFilePermission;
function newFilePermission() {
    return {
        pk: 0,
        user_pk: 0,
        path: '',
        read: false,
        write: false,
    };
}
