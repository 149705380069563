"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getViewerUrl = exports.createFullPath = void 0;
const service_file_type_1 = require("./service-file-type");
const createFullPath = (path, dirEntry) => dirEntry.name === '' ? (path === '' ? '/' : path) : `${path === '/' ? '' : path}/${dirEntry.name}`;
exports.createFullPath = createFullPath;
const getViewerUrl = (dirEntry, path, token = '') => {
    const urlEncodedFullPath = encodeURIComponent((0, exports.createFullPath)(path, dirEntry));
    if (dirEntry.is_dir) {
        return `/web/file-manager/list?path=${urlEncodedFullPath}&token=${token}`;
    }
    // From here, item is a file
    const ext = dirEntry.name.substring(dirEntry.name.lastIndexOf('.') + 1).toLowerCase();
    if (service_file_type_1.ServiceFileExtensions[service_file_type_1.ServiceFileType.PDF].includes(ext)) {
        return `/viewer/pdf?file=/contents${urlEncodedFullPath}${token !== '' ? `__with_token=${token}` : ''}`;
    }
    else if (service_file_type_1.ServiceFileExtensions[service_file_type_1.ServiceFileType.Video].includes(ext)) {
        return `/viewer/video?file=/contents${urlEncodedFullPath}&token=${token}`;
    }
    else if (service_file_type_1.ServiceFileExtensions[service_file_type_1.ServiceFileType.Audio].includes(ext)) {
        return `/viewer/audio?file=/contents${urlEncodedFullPath}&token=${token}`;
    }
    else if (service_file_type_1.ServiceFileExtensions[service_file_type_1.ServiceFileType.Image].includes(ext)) {
        return `/viewer/image?file=/contents${urlEncodedFullPath}&token=${token}`;
    }
    else if (service_file_type_1.ServiceFileExtensions[service_file_type_1.ServiceFileType.Text].includes(ext)) {
        return `/viewer/text?file=/contents${urlEncodedFullPath}&token=${token}`;
    }
    else if (service_file_type_1.ServiceFileExtensions[service_file_type_1.ServiceFileType.Manga].includes(ext)) {
        return `/viewer/manga?file=/contents${urlEncodedFullPath}&token=${token}`;
    }
    else {
        console.log('unsupported file: ', dirEntry.name);
        return '';
    }
};
exports.getViewerUrl = getViewerUrl;
