"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineTaskList = exports.TaskList = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-tables/lib-es2015");
const lib_es2015_3 = require("@hodol/complex-inputs/lib-es2015");
const Constants = require("./constants");
const lib_es2015_4 = require("@hodol/web-component-helper/lib-es2015");
const apis_1 = require("./apis");
class TaskList extends HTMLElement {
    constructor() {
        super();
        this.router = (0, lib_es2015_4.useRouter)();
        this.apis = new apis_1.default();
        this.autoRefreshIntervalId = -1;
        this.fetchData = () => __awaiter(this, void 0, void 0, function* () {
            const taskList = yield this.apis.listTasks();
            if (taskList === null) {
                this.table.setData([]);
                return;
            }
            console.log(`setup data in table`);
            this.table.setData(taskList);
        });
        this.style.setProperty('display', 'block');
        this.innerHTML = `
      <div class="table-with-pageable">
        <div class="table-container">
          <div class="table-header">
            Auto Refresh <input type="checkbox" class="auto-refresh"> <button class="refresh">Refresh</button>
          </div>
          <div class="list-container">
            <dynamic-table primary-key-name="pk" fixed-field-names="pk" field-names="description,status,progress,user_pk,message,buttons" is-show-checkbox="true"></dynamic-table>
          </div>
        </div>
      </div>
    `;
        this.table = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, `dynamic-table`);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.table.createVirtualCell = (content, primaryKey, fieldName, rowIndex, columnIndex) => {
            const entity = content;
            if (fieldName === 'buttons') {
                const cell = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div><button-list button-names="delete"></button-list></div>`);
                (0, lib_es2015_1.getQuerySelectOrThrowError)(cell, 'button-list').addEventListener('click', (e) => {
                    const btnName = e.detail;
                    if (btnName === 'delete') {
                        const answer = confirm(`Are you sure to delete ${Constants.subjectName}? pk: ${primaryKey}`);
                        if (!answer)
                            return;
                        void (() => __awaiter(this, void 0, void 0, function* () {
                            const apiResp = yield this.apis.deleteTaskByPK({ pk: `${primaryKey}` });
                            if (apiResp === null) {
                                return;
                            }
                            alert(`The ${Constants.subjectName} with pk, ${entity.pk} have been deleted!`);
                            yield this.fetchData();
                        }))();
                    }
                });
                return cell;
            }
            else if (fieldName === 'progress') {
                const progress = `${entity[fieldName]}`;
                const cell = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div>
          <div>${progress}%</div>
          <div class="progress-bar-container" >
            <div class="progress-bar"></div>
          </div>
        </div>`);
                const progressBarContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(cell, 'div.progress-bar-container');
                const progressBar = (0, lib_es2015_1.getQuerySelectOrThrowError)(cell, 'div.progress-bar');
                cell.style.setProperty('width', '10rem');
                cell.style.setProperty('display', 'flex');
                cell.style.setProperty('flex-direction', 'column');
                cell.style.setProperty('align-items', 'center');
                cell.style.setProperty('justify-content', 'center');
                progressBarContainer.style.setProperty('width', '90%');
                progressBarContainer.style.setProperty('border', '1px solid #aaaaaa');
                progressBar.style.setProperty('background-color', '#00aa00');
                progressBar.style.setProperty('width', `${progress}%`);
                progressBar.style.setProperty('height', '1rem');
                return cell;
            }
            else if (fieldName === 'description') {
                const cell = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div>${entity[fieldName]}</div>`);
                cell.style.setProperty('display', 'block');
                cell.style.setProperty('min-width', '30rem');
                cell.style.setProperty('max-width', '40rem');
                cell.style.setProperty('text-wrap', 'unset');
                cell.style.setProperty('word-break', 'break-all');
                return cell;
            }
            else if (fieldName === 'message') {
                const cell = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div>${entity[fieldName]}</div>`);
                cell.style.setProperty('display', 'block');
                cell.style.setProperty('white-space', 'pre');
                cell.style.setProperty('font-family', 'monospace');
                cell.style.setProperty('line-height', '150%');
                return cell;
            }
            else {
                const cell = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div>${entity[fieldName]}</div>`);
                cell.style.setProperty('display', 'flex');
                cell.style.setProperty('flex-direction', 'column');
                cell.style.setProperty('align-items', 'center');
                cell.style.setProperty('justify-content', 'center');
                return cell;
            }
        };
        const autoRefreshCheckbox = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.auto-refresh');
        autoRefreshCheckbox.addEventListener('change', () => {
            if (autoRefreshCheckbox.checked) {
                this.autoRefreshIntervalId = window.setInterval(this.fetchData, 2000);
            }
            else {
                window.clearInterval(this.autoRefreshIntervalId);
                this.autoRefreshIntervalId = -1;
            }
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.refresh').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                yield this.fetchData();
            }))();
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchData();
        });
    }
    disconnectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.autoRefreshIntervalId !== -1) {
                window.clearInterval(this.autoRefreshIntervalId);
            }
        });
    }
}
exports.TaskList = TaskList;
const defineTaskList = (name) => {
    (0, lib_es2015_2.defineDynamicTable)('dynamic-table');
    (0, lib_es2015_3.defineButtonList)('button-list');
    (0, lib_es2015_4.defineIfUndefined)(name, TaskList);
};
exports.defineTaskList = defineTaskList;
