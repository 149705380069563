"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineEntranceList = exports.EntranceList = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
class EntranceList extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_2.useRouter)();
        this.fetchItems = () => __awaiter(this, void 0, void 0, function* () {
            const apiResp = yield this.apis.getEntrances();
            if (apiResp === null) {
                alert(`fail to get start point to explore the data....`);
                return;
            }
            apiResp.forEach(path => {
                const button = (0, lib_es2015_1.buildNodeByOuterHTML)(`<button>${path}</button>`);
                button.addEventListener('click', () => {
                    this.router.push(`/web/file-manager/list?path=${encodeURIComponent(path)}`);
                });
                this.entranceContainer.appendChild(button);
            });
        });
        this.style.setProperty('display', 'block');
        this.innerHTML = `<div class="entrance-container"></div>`;
        this.entranceContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.entrance-container');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchItems();
        });
    }
}
exports.EntranceList = EntranceList;
const defineEntranceList = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, EntranceList);
};
exports.defineEntranceList = defineEntranceList;
