"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineAlert = exports.Alert = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const common_1 = require("./common");
class Alert extends HTMLElement {
    constructor() {
        super();
        this.showPopup = (message) => {
            this.popup.innerHTML = message;
            this.style.setProperty('display', 'flex');
        };
        this.hidePopup = () => {
            this.style.setProperty('display', 'none');
        };
        this.innerHTML = `<div class="popup"></div>`;
        this.popup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.popup');
        (0, common_1.setPopupBackgroundStyle)(this);
        (0, common_1.setPopupStyle)(this.popup);
        this.addEventListener('click', () => {
            this.hidePopup();
        });
        this.popup.addEventListener('click', (e) => {
            // ignore the click event on 'this' when user clicks the 'this.popup'.
            e.stopPropagation();
        });
    }
}
exports.Alert = Alert;
const defineAlert = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, Alert);
};
exports.defineAlert = defineAlert;
