"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.define = exports.PlaylistViewer = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const playlist_items_popup_1 = require("./playlist-items-popup");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const service_file_type_1 = require("../file-manager/service-file-type");
const apis_1 = require("../file-manager/apis");
const toast_box_1 = require("./toast-box");
const repeat_button_1 = require("./repeat-button");
const apis_2 = require("../playlist/apis");
const timeStepSec = 10;
const CONTENTS_ROOT = '/contents';
class PlaylistViewer extends HTMLElement {
    constructor() {
        super();
        this.timeUpdateLock = false;
        this.imageSlideShowTimerID = 0;
        this.fileManagerApis = new apis_1.FileManagerApis();
        this.playlistApis = new apis_2.PlaylistApis();
        this.onKeydownOnMediaCommon = (e, target) => {
            if (e.key === ' ') {
                if (target.paused) {
                    target.play();
                    this.toastBox.show(true);
                }
                else {
                    target.pause();
                }
            }
            else if (e.key === 'ArrowLeft') {
                let factor = 1;
                if (e.shiftKey)
                    factor = factor * 1.5;
                if (e.altKey)
                    factor = factor * 2;
                target.currentTime = Math.max(0, target.currentTime - (factor * timeStepSec));
                this.toastBox.setMessage(`&lt;&lt; ${(factor * timeStepSec)}sec`);
                this.toastBox.show(true);
            }
            else if (e.key === 'ArrowRight') {
                let factor = 1;
                if (e.shiftKey)
                    factor = factor * 1.5;
                if (e.altKey)
                    factor = factor * 2;
                target.currentTime = Math.min(target.duration, target.currentTime + (factor * timeStepSec));
                this.toastBox.setMessage(`&gt;&gt; ${(factor * timeStepSec)}sec`);
                this.toastBox.show(true);
            }
            else if (e.key === 'ArrowDown') {
                target.volume = Math.max(0, target.volume - 0.1);
                this.toastBox.setMessage(`Volume: ${(target.volume * 100).toFixed(1)}%`);
                this.toastBox.show(true);
            }
            else if (e.key === 'ArrowUp') {
                target.volume = Math.min(1, target.volume + 0.1);
                this.toastBox.setMessage(`Volume: ${(target.volume * 100).toFixed(1)}%`);
                this.toastBox.show(true);
            }
        };
        this.hideAllViewers = () => __awaiter(this, void 0, void 0, function* () {
            yield this.viewerFadeOut();
            this.videoPlayer.pause();
            this.videoPlayer.style.setProperty('display', 'none');
            this.audioPlayer.pause();
            this.audioPlayer.style.setProperty('display', 'none');
            if (this.imageSlideShowTimerID > 0) {
                clearTimeout(this.imageSlideShowTimerID);
                this.imageSlideShowTimerID = 0;
            }
            this.imageViewer.style.setProperty('display', 'none');
            this.toastBox.style.setProperty('display', 'none');
        });
        this.parseHHmmssToSec = (hhmmss) => {
            const split = hhmmss.split(':');
            if (split.length < 3) {
                throw Error(`wrong format of hhmmss: it should look like 12:34:56, given: ${hhmmss}`);
            }
            return Number(split[0]) * 60 * 60 + Number(split[1]) * 60 + Number(split[2]);
        };
        this.viewerFadeOut = () => __awaiter(this, void 0, void 0, function* () {
            this.viewerContainer.classList.add('fadeout');
            yield new Promise(resolve => setTimeout(resolve, 1000));
            this.viewerContainer.classList.remove('fadeout');
            void this.viewerContainer.offsetWidth;
        });
        this.viewerFadeIn = () => __awaiter(this, void 0, void 0, function* () {
            this.viewerContainer.classList.add('fadein');
            yield new Promise(resolve => setTimeout(resolve, 1000));
            this.viewerContainer.classList.remove('fadein');
            void this.viewerContainer.offsetWidth;
        });
        this.loadPlaylist = (pk) => __awaiter(this, void 0, void 0, function* () {
            const playlist = yield this.playlistApis.getPlaylistByPK({ pk });
            if (playlist === null) {
                alert(`fail to load playlist of pk: ${pk}`);
                return;
            }
            this.playlistItemsPopup.setData(playlist.items);
        });
        this.innerHTML = `
      <div class="header">
        <select class="playlist">
          <option value="0">Playlists...</option>
        </select>
        <repeat-button></repeat-button>
        <button class="toggle-playlist-popup">playlist</button>
      </div>
      <div class="body">
        <div class="viewers">
          <video controls></video>
          <audio controls></audio>
          <img src='' alt=''>
        </div>
        <playlist-items-popup></playlist-items-popup>
      </div>
      <toast-box></toast-box>
    `;
        this.playlistItemsPopup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'playlist-items-popup');
        this.viewerContainer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.viewers');
        this.videoPlayer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'video');
        this.audioPlayer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'audio');
        this.imageViewer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'img');
        this.toastBox = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'toast-box');
        this.repeatBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'repeat-button');
        this.playlistSelect = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'select.playlist');
        this.videoPlayer.style.setProperty('display', 'none');
        this.audioPlayer.style.setProperty('display', 'none');
        this.imageViewer.style.setProperty('display', 'none');
        this.toastBox.style.setProperty('display', 'none');
        this.playlistItemsPopup.addEventListener(playlist_items_popup_1.EVENT_SELECT_ITEM, (e) => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                const playlistItem = e.detail;
                yield this.hideAllViewers();
                const ext = playlistItem.file_path.substring(playlistItem.file_path.lastIndexOf('.') + 1);
                const svcFileType = (0, service_file_type_1.isServiceFileType)(ext);
                if (svcFileType === service_file_type_1.ServiceFileType.Video) {
                    (0, lib_es2015_2.removeAllChildren)(this.videoPlayer);
                    const sourceTag = document.createElement('source');
                    sourceTag.src = CONTENTS_ROOT + playlistItem.file_path;
                    this.videoPlayer.appendChild(sourceTag);
                    const subtitleFilePath = `${playlistItem.file_path.substring(0, playlistItem.file_path.lastIndexOf('.'))}.vtt`;
                    let isSubtitleExist = yield this.fileManagerApis.isAtLeastOneFileExist([subtitleFilePath]);
                    if (isSubtitleExist === null) {
                        console.error(`fail to check if subtitle file exist.`);
                        isSubtitleExist = false;
                    }
                    if (isSubtitleExist) {
                        const trackTag = document.createElement('track');
                        trackTag.kind = 'subtitles';
                        trackTag.src = CONTENTS_ROOT + subtitleFilePath;
                        trackTag.default = true;
                        this.videoPlayer.appendChild(trackTag);
                    }
                    this.videoPlayer.load();
                    this.videoPlayer.currentTime = playlistItem.start_at !== '' ? this.parseHHmmssToSec(playlistItem.start_at) : 0;
                    this.endAt = playlistItem.end_at !== '' ? this.parseHHmmssToSec(playlistItem.end_at) : 0;
                    this.videoPlayer.style.setProperty('display', 'block');
                    yield this.viewerFadeIn();
                    yield this.videoPlayer.play();
                }
                else if (svcFileType === service_file_type_1.ServiceFileType.Audio) {
                    this.audioPlayer.src = CONTENTS_ROOT + playlistItem.file_path;
                    this.audioPlayer.currentTime = playlistItem.start_at !== '' ? this.parseHHmmssToSec(playlistItem.start_at) : 0;
                    this.endAt = playlistItem.end_at !== '' ? this.parseHHmmssToSec(playlistItem.end_at) : 0;
                    this.audioPlayer.load();
                    this.audioPlayer.style.setProperty('display', 'block');
                    yield this.viewerFadeIn();
                    yield this.audioPlayer.play();
                }
                else if (svcFileType === service_file_type_1.ServiceFileType.Image) {
                    this.imageViewer.src = CONTENTS_ROOT + playlistItem.file_path;
                    this.imageViewer.style.setProperty('display', 'block');
                    yield this.viewerFadeIn();
                    if (playlistItem.duration !== '') {
                        this.imageSlideShowTimerID = window.setTimeout(() => {
                            this.playlistItemsPopup.playNext();
                        }, this.parseHHmmssToSec(playlistItem.duration) * 1000);
                    }
                }
            }))();
        });
        this.videoPlayer.addEventListener('ended', () => {
            this.playlistItemsPopup.playNext();
        });
        this.videoPlayer.addEventListener('timeupdate', () => {
            if (this.endAt === 0)
                return;
            if (this.endAt < this.videoPlayer.currentTime && !this.timeUpdateLock) {
                this.timeUpdateLock = true;
                this.playlistItemsPopup.playNext();
                setTimeout(() => { this.timeUpdateLock = false; }, 1000);
            }
        });
        this.videoPlayer.addEventListener('canplay', () => {
            this.toastBox.hide();
        });
        this.videoPlayer.addEventListener('waiting', () => {
            this.toastBox.setMessage('Loading...');
            this.toastBox.show(false);
        });
        this.videoPlayer.addEventListener('keydown', (e) => e.preventDefault(), true);
        this.audioPlayer.addEventListener('ended', () => {
            this.playlistItemsPopup.playNext();
        });
        this.audioPlayer.addEventListener('timeupdate', () => {
            if (this.endAt === 0)
                return;
            if (this.endAt < this.audioPlayer.currentTime && !this.timeUpdateLock) {
                this.timeUpdateLock = true;
                this.playlistItemsPopup.playNext();
                setTimeout(() => { this.timeUpdateLock = false; }, 1000);
            }
        });
        this.audioPlayer.addEventListener('canplay', () => {
            this.toastBox.hide();
        });
        this.audioPlayer.addEventListener('waiting', () => {
            this.toastBox.setMessage('Loading...');
            this.toastBox.show(false);
        });
        this.audioPlayer.addEventListener('keydown', (e) => e.preventDefault(), true);
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.toggle-playlist-popup').addEventListener('click', () => {
            this.playlistItemsPopup.style.setProperty('display', this.playlistItemsPopup.style.getPropertyValue('display') === 'none' ? 'block' : 'none');
        });
        this.repeatBtn.addEventListener('change-repeat', (e) => {
            this.playlistItemsPopup.setRepeat(e.detail);
        });
        this.playlistSelect.addEventListener('change', () => {
            if (this.playlistSelect.value === '0')
                return;
            void (() => __awaiter(this, void 0, void 0, function* () {
                yield this.loadPlaylist(Number(this.playlistSelect.value));
            }))();
        });
        document.body.addEventListener('keydown', (e) => {
            if (this.videoPlayer.style.getPropertyValue('display') !== 'none') {
                this.onKeydownOnMediaCommon(e, this.videoPlayer);
                if (e.key === 'Enter') {
                    if (!document.fullscreenElement) {
                        this.videoPlayer.requestFullscreen();
                    }
                    else {
                        document.exitFullscreen();
                    }
                }
            }
            else if (this.audioPlayer.style.getPropertyValue('display') !== 'none') {
                this.onKeydownOnMediaCommon(e, this.audioPlayer);
            }
            if (e.key === 'PageUp') {
                this.playlistItemsPopup.playPrev();
            }
            else if (e.key === 'PageDown') {
                this.playlistItemsPopup.playNext();
            }
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.playlistApis.listPlaylists({ page_no: 0, page_size: 0, query: '' });
            if (resp === null) {
                alert(`fail to get playlists`);
                return;
            }
            resp.items.forEach(playlist => {
                const opt = document.createElement('OPTION');
                opt.value = `${playlist.pk}`;
                opt.innerHTML = playlist.name;
                this.playlistSelect.appendChild(opt);
            });
            // CAUTION: Starting playlist which include video/audio automatically does not work because most modern browsers are block it.
            this.playlistSelect.value = (0, lib_es2015_2.getAttributeOrDefault)(this, 'playlist-pk', '0');
            this.playlistSelect.dispatchEvent(new Event('change'));
        });
    }
}
exports.PlaylistViewer = PlaylistViewer;
const define = (name) => {
    (0, playlist_items_popup_1.definePlaylistItemsPopup)('playlist-items-popup');
    (0, toast_box_1.defineToastBox)('toast-box');
    (0, repeat_button_1.defineRepeatButton)('repeat-button');
    (0, lib_es2015_1.defineIfUndefined)(name, PlaylistViewer);
};
exports.define = define;
