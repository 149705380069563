"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.definePreviewBox = exports.PreviewBox = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const service_file_type_1 = require("./service-file-type");
const DIR_ICON_PATH = '/files/assets/icon-directory.svg';
const FILE_ICON_PATH = '/files/assets/icon-file.svg';
const TXT_ICON_PATH = '/files/assets/icon-document-file.svg';
const IMG_ICON_PATH = '/files/assets/icon-image-file.svg';
const VIDEO_ICON_PATH = '/files/assets/icon-video-file.svg';
const AUDIO_ICON_PATH = '/files/assets/icon-audio-file.svg';
const ServiceFileIconPath = {
    Unknown: FILE_ICON_PATH,
    Video: VIDEO_ICON_PATH,
    Audio: AUDIO_ICON_PATH,
    Image: IMG_ICON_PATH,
    Text: TXT_ICON_PATH,
    Manga: FILE_ICON_PATH,
    Subtitle: FILE_ICON_PATH,
    PDF: FILE_ICON_PATH,
    SMI: FILE_ICON_PATH,
    SevenZip: FILE_ICON_PATH,
};
class PreviewBox extends HTMLElement {
    constructor() {
        super();
        this.targetFilePath = '';
        this.style.setProperty('display', 'block');
        this.style.setProperty('width', '96px');
        this.targetFilePath = (0, lib_es2015_1.getAttributeOrThrowError)(this, 'target');
        this.isDir = (0, lib_es2015_1.getAttributeOrThrowError)(this, 'is-dir') == 'true';
        if (this.isDir) {
            this.innerHTML = `<img class="icon" src="${DIR_ICON_PATH}">`;
            return;
        }
        const lastSlashAt = this.targetFilePath.lastIndexOf('/');
        this.diskMetadataDirPath = `/contents${this.targetFilePath.substring(0, lastSlashAt)}/.sfv-metadata/${this.targetFilePath.substring(lastSlashAt + 1, this.targetFilePath.length)}`;
        const ext = this.targetFilePath.substring(this.targetFilePath.lastIndexOf('.') + 1, this.targetFilePath.length);
        const svcFileType = (0, service_file_type_1.isServiceFileType)(ext);
        if ([service_file_type_1.ServiceFileType.PDF, service_file_type_1.ServiceFileType.Manga, service_file_type_1.ServiceFileType.Image].includes(svcFileType)) {
            this.innerHTML = `<img src='${this.diskMetadataDirPath}/preview_96x72.jpg' />`;
            const previewImg = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'img');
            previewImg.addEventListener('error', () => {
                previewImg.src = `${ServiceFileIconPath[svcFileType]}`;
                previewImg.classList.add('icon');
            });
        }
        else if ([service_file_type_1.ServiceFileType.Video].includes(svcFileType)) {
            this.innerHTML = `<div>
          <img src='${this.diskMetadataDirPath}/cover_96x72.jpg' />
          <video loop muted playsinline>
            <source src='${this.diskMetadataDirPath}/preview_96x72.mkv' />
          </video>
        </div>`;
            const container = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div');
            const coverImg = (0, lib_es2015_1.getQuerySelectOrThrowError)(container, 'img');
            const previewMov = (0, lib_es2015_1.getQuerySelectOrThrowError)(container, 'video');
            previewMov.style.setProperty('display', 'none');
            coverImg.addEventListener('error', () => {
                coverImg.src = `${ServiceFileIconPath[svcFileType]}`;
                coverImg.classList.add('icon');
            });
            const onMouseOver = () => {
                coverImg.style.setProperty('display', 'none');
                previewMov.style.setProperty('display', 'inline-block');
                previewMov.play();
            };
            container.addEventListener('mouseover', onMouseOver);
            const onMouseLeave = () => {
                coverImg.style.setProperty('display', 'inline');
                previewMov.style.setProperty('display', 'none');
                previewMov.pause();
            };
            container.addEventListener('mouseleave', onMouseLeave);
            (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'source').addEventListener('error', () => {
                container.removeEventListener('mouseover', onMouseOver);
                container.removeEventListener('mouseleave', onMouseLeave);
            });
        }
        else {
            this.innerHTML = `<img class="icon" src=${ServiceFileIconPath[svcFileType]}>`;
        }
    }
    connectedCallback() {
    }
}
exports.PreviewBox = PreviewBox;
const definePreviewBox = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, PreviewBox);
};
exports.definePreviewBox = definePreviewBox;
