"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineSharing = exports.Sharing = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const apis_1 = require("../../file-permission/apis");
const common_1 = require("../common");
const common_2 = require("./common");
class Sharing extends HTMLElement {
    constructor() {
        super();
        this.filePermissionApis = new apis_1.default();
        this.showPopup = (dirEntry, path) => {
            this.dirEntry = dirEntry;
            this.path = path;
            this.viewUrlInput.value = '';
            this.downloadUrlInput.value = '';
            this.style.setProperty('display', 'flex');
        };
        this.hidePopup = () => {
            this.dirEntry = undefined;
            this.path = undefined;
            this.viewUrlInput.value = '';
            this.downloadUrlInput.value = '';
            this.style.setProperty('display', 'none');
        };
        this.innerHTML = `<div class="popup">
      <div class="popup-sub-container">
        <button class="generate">Generate</button>
        <input class="view-url" /><button class="copy-view-url">copy</button>
        <input class="download-url" /><button class="copy-download-url">copy</button>
      </div>
    </div>`;
        this.popup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.popup');
        (0, common_2.setPopupBackgroundStyle)(this);
        (0, common_2.setPopupStyle)(this.popup);
        this.addEventListener('click', () => {
            this.hidePopup();
        });
        this.popup.addEventListener('click', (e) => {
            // ignore the click event on 'this' when user clicks the 'this.popup'.
            e.stopPropagation();
        });
        this.viewUrlInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.view-url');
        this.downloadUrlInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.download-url');
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.generate').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                if (this.dirEntry === undefined) {
                    alert('dirEntry is undefined');
                    return;
                }
                if (this.path === undefined) {
                    alert('path is undefined');
                    return;
                }
                const tempToken = yield this.filePermissionApis.registerTempFileReadPermission(`${(0, common_1.createFullPath)(this.path, this.dirEntry)}`);
                if (tempToken === null) {
                    alert('fail to generate token');
                    return;
                }
                const url = new URL(window.location.href);
                this.viewUrlInput.value = `${url.protocol}//${url.host}${(0, common_1.getViewerUrl)(this.dirEntry, this.path, tempToken)}`;
                this.downloadUrlInput.value = `${url.protocol}//${url.host}/contents${(0, common_1.createFullPath)(this.path, this.dirEntry)}?token=${tempToken}`;
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.copy-view-url').addEventListener('click', () => {
            navigator.clipboard.writeText(this.viewUrlInput.value);
            alert('the view url copied');
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.copy-download-url').addEventListener('click', () => {
            navigator.clipboard.writeText(this.downloadUrlInput.value);
            alert('the download url copied');
        });
    }
}
exports.Sharing = Sharing;
const defineSharing = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, Sharing);
};
exports.defineSharing = defineSharing;
