"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPopupStyle = exports.setPopupBackgroundStyle = void 0;
const setPopupBackgroundStyle = (backgroundElt) => {
    const backgroundStyle = {
        'display': 'none',
        'position': 'fixed',
        'left': '0',
        'top': '0',
        'width': '100%',
        'height': '100%',
        'justify-content': 'center',
        'align-content': 'center',
        'align-items': 'center',
        'background-color': 'rgba(0, 0, 0, 0.6)',
        'backdrop-filter': 'blur(4px)',
    };
    Object.keys(backgroundStyle).forEach(styleName => backgroundElt.style.setProperty(styleName, backgroundStyle[styleName]));
};
exports.setPopupBackgroundStyle = setPopupBackgroundStyle;
const setPopupStyle = (popupContainer) => {
    const popupStyle = {
        'min-width': '50%',
        'min-height': '30%',
        'max-width': '50%',
        'max-height': '50%',
        'background-color': 'white',
        'display': 'flex',
        'align-content': 'center',
        'justify-content': 'center',
        'align-items': 'center',
        'border-radius': '1rem',
        'font-size': '1.2rem',
    };
    Object.keys(popupStyle).forEach(styleName => popupContainer.style.setProperty(styleName, popupStyle[styleName]));
};
exports.setPopupStyle = setPopupStyle;
