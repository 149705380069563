"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineRepeatButton = exports.RepeatButton = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const repeatMethods = ['none', 'repeat-all', 'repeat-one', 'random'];
class RepeatButton extends HTMLElement {
    constructor() {
        super();
        this.status = 'none';
        this.statusText = {
            'none': 'No Repeat',
            'repeat-all': 'All',
            'repeat-one': 'One',
            'random': 'Random',
        };
        this.innerHTML = `<button>${this.statusText[this.status]}</button>`;
        const btn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button');
        btn.addEventListener('click', () => {
            const currentIdx = repeatMethods.indexOf(this.status);
            if (currentIdx === repeatMethods.length - 1) {
                this.status = repeatMethods[0];
            }
            else {
                this.status = repeatMethods[currentIdx + 1];
            }
            btn.innerHTML = `${this.statusText[this.status]}`;
            this.dispatchEvent(new CustomEvent('change-repeat', { detail: this.status }));
        });
    }
}
exports.RepeatButton = RepeatButton;
const defineRepeatButton = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, RepeatButton);
};
exports.defineRepeatButton = defineRepeatButton;
