"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineProgress = exports.Progress = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const common_1 = require("./common");
class Progress extends HTMLElement {
    constructor() {
        super();
        this.showPopup = () => {
            this.style.setProperty('display', 'flex');
        };
        this.hidePopup = () => {
            this.style.setProperty('display', 'none');
        };
        this.setPopupContents = (message, progress) => {
            this.messageBox.innerHTML = message;
            this.progressBar.style.setProperty('width', `${progress}%`);
        };
        this.innerHTML = `<div class="popup">
      <div class="message-box"></div>
      <div class="progress-bar-container">
        <div class="progress-bar"></div>
      </div>
    </div>`;
        this.popup = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.popup');
        (0, common_1.setPopupBackgroundStyle)(this);
        (0, common_1.setPopupStyle)(this.popup);
        this.popup.style.setProperty('flex-direction', 'column');
        this.messageBox = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.message-box');
        this.progressBarContainer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.progress-bar-container');
        this.progressBarContainer.style.setProperty('border', '1px solid #888888');
        this.progressBarContainer.style.setProperty('width', '80%');
        this.progressBar = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.progress-bar');
        this.progressBar.style.setProperty('height', '1rem');
        this.progressBar.style.setProperty('background-color', '#00aa00');
    }
}
exports.Progress = Progress;
const defineProgress = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, Progress);
};
exports.defineProgress = defineProgress;
