"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineFilePermissionListEditor = exports.FilePermissionListEditor = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const common_1 = require("@hodol/complex-inputs/lib-es2015/common");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const entity_1 = require("./entity");
const TEXTAREA_PADDING = '0.2rem 0.2rem';
const BUTTON_WIDTH = 25;
const ATTR_MAX_TEXTAREA_WIDTH = 'max-textarea-width';
class FilePermissionListEditor extends HTMLElement {
    constructor() {
        super();
        this.addItem = (filePermission) => {
            const neoPathTextarea = (0, common_1.createDefaultTextarea)({ padding: TEXTAREA_PADDING, 'overflow-y': 'hidden' });
            neoPathTextarea.setAttribute('primary-key', `${filePermission.pk}`);
            neoPathTextarea.classList.add('path');
            neoPathTextarea.addEventListener('input', () => {
                this.resizeTextarea(neoPathTextarea);
            });
            this.appendChild(neoPathTextarea);
            neoPathTextarea.value = filePermission.path;
            this.resizeTextarea(neoPathTextarea);
            const neoReadInputCell = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div primary-key="${filePermission.pk}" class="checkbox-container read">
      <input type="checkbox" primary-key="${filePermission.pk}" class="read" ${filePermission.read ? 'checked' : ''}/>
    </div>`);
            this.appendChild(neoReadInputCell);
            const neoWriteInputCell = (0, lib_es2015_1.buildNodeByOuterHTML)(`<div primary-key="${filePermission.pk}" class="checkbox-container write">
      <input type="checkbox" primary-key="${filePermission.pk}" class="write" ${filePermission.write ? 'checked' : ''}/>
    </div>`);
            this.appendChild(neoWriteInputCell);
            const deleteBtn = document.createElement('button');
            deleteBtn.classList.add('delete-item');
            deleteBtn.innerText = '-';
            deleteBtn.addEventListener('click', () => {
                this.removeChild(neoPathTextarea);
                this.removeChild(neoReadInputCell);
                this.removeChild(neoWriteInputCell);
                this.removeChild(deleteBtn);
            });
            this.appendChild(deleteBtn);
            neoPathTextarea.dataset.pk = `${filePermission.pk}`;
            neoPathTextarea.dataset.user_pk = `${filePermission.user_pk}`;
        };
        this.removeAll = () => {
            while (this.children.length > 4) {
                this.removeChild((0, lib_es2015_1.getLastChildOrThrowError)(this));
            }
        };
        this.resizeTextarea = (textarea) => {
            const dimension = (0, lib_es2015_1.getVirtualDivDimension)(textarea.value, (0, common_1.buildVirtualDivStyle)(`${this.maxTextareaWidth}px`, TEXTAREA_PADDING));
            textarea.setAttribute(common_1.ATTR_DATA_WIDTH, `${dimension.width}`);
            const requiredTextareaWidth = this.getRequiredTextareaWidth();
            this.style.setProperty(common_1.STYLE_GRID_TEMPLATE_COLUMNS, `${requiredTextareaWidth}px 50px 50px ${BUTTON_WIDTH}px`);
            textarea.style.setProperty(common_1.STYLE_HEIGHT, `${dimension.height}px`);
            this.dispatchEvent(new CustomEvent(common_1.EVENT_DIMENSION_CHANGE, {
                detail: { requiredTextareaWidth, buttonWidth: BUTTON_WIDTH, requiredHeight: this.getRequiredHeight() },
            }));
        };
        this.setTextareaWidth = (textareaWidth) => {
            this.style.setProperty(common_1.STYLE_GRID_TEMPLATE_COLUMNS, `${textareaWidth}px 50px 50px ${BUTTON_WIDTH}px`);
        };
        this.getRequiredTextareaWidth = () => {
            let maxWidth = 50;
            this.querySelectorAll(`[${common_1.ATTR_DATA_WIDTH}]`).forEach(item => {
                maxWidth = Math.max(maxWidth, Number(item.getAttribute(common_1.ATTR_DATA_WIDTH)));
            });
            return maxWidth;
        };
        this.getButtonWidth = () => BUTTON_WIDTH;
        this.getRequiredHeight = () => (0, common_1.getComputedHeight)(this);
        this.maxTextareaWidth = Number((0, lib_es2015_1.getAttributeOrDefault)(this, ATTR_MAX_TEXTAREA_WIDTH, '200'));
        this.style.setProperty(common_1.STYLE_DISPLAY, common_1.STYLE_DISPLAY_INLINE_GRID);
        this.style.setProperty(common_1.STYLE_GRID_TEMPLATE_COLUMNS, `50px 50px 50px ${BUTTON_WIDTH}px`);
        this.innerHTML = `<div class="path header" data-width="50">path</div>
      <div class="read header" data-width="50">read</div>
      <div class="write header" data-width="50">write</div>
      <button class="add-item">+</button>`;
        const addBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.add-item');
        addBtn.addEventListener('click', () => this.addItem((0, entity_1.newFilePermission)()));
    }
    get value() {
        const values = [];
        const allPKs = [];
        this.querySelectorAll('textarea.path').forEach(div => allPKs.push(Number((0, lib_es2015_1.getAttributeOrThrowError)(div, 'primary-key'))));
        allPKs.forEach(pk => {
            const pathTextarea = this.querySelector(`[primary-key='${pk}'].path`);
            const readInput = this.querySelector(`input[primary-key='${pk}'].read`);
            const writeInput = this.querySelector(`input[primary-key='${pk}'].write`);
            values.push({
                pk,
                user_pk: Number(pathTextarea.dataset.user_pk),
                path: pathTextarea.value,
                read: readInput.checked,
                write: writeInput.checked,
            });
        });
        return values;
    }
    set value(values) {
        this.removeAll();
        values.forEach(v => this.addItem(v));
    }
}
exports.FilePermissionListEditor = FilePermissionListEditor;
const defineFilePermissionListEditor = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, FilePermissionListEditor);
};
exports.defineFilePermissionListEditor = defineFilePermissionListEditor;
