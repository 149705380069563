"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.definePlaylistItemEdit = exports.PlaylistItemEdit = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
class PlaylistItemEdit extends HTMLElement {
    constructor() {
        super();
        this.style.setProperty('display', 'grid');
        this.innerHTML = `
      <table>
        <thead>
          <tr>
            <td class="header change-order">move</td>
            <td class="header pk">pk</td>
            <td class="header playlist-pk">playlist pk</td>
            <td class="header file-path">path</td>
            <td class="header start-at">start_at</td>
            <td class="header end-at">end_at</td>
            <td class="header duration">duration</td>
            <td class="header buttons">delete</td>
          </tr>
        </thead>
        <tbody></tbody>
      </table>`;
        this.tableBody = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'tbody');
        const tr = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'thead tr');
        tr.addEventListener('mouseenter', () => {
            if (this.movingTr !== undefined && this.movingTr !== tr) {
                tr.querySelectorAll('td').forEach(td => td.classList.add('changing-order'));
            }
        });
        tr.addEventListener('mouseleave', () => {
            if (this.movingTr !== undefined && this.movingTr !== tr) {
                tr.querySelectorAll('td').forEach(td => td.classList.remove('changing-order'));
            }
        });
        tr.addEventListener('mouseup', () => {
            if (this.movingTr !== undefined && this.movingTr !== tr) {
                this.tableBody.insertBefore(this.movingTr, this.tableBody.firstChild);
            }
        });
        this.addEventListener('mouseup', () => {
            this.movingTr = undefined;
            this.classList.remove('changing-order');
            this.querySelectorAll('td.changing-order').forEach(td => td.classList.remove('changing-order'));
        });
    }
    set value(playlistItemList) {
        this.tableBody.innerHTML = '';
        playlistItemList.sort((a, b) => a.order < b.order ? -1 : 1);
        playlistItemList.forEach(playlistItem => {
            const tr = document.createElement('TR');
            tr.setAttribute('data-pk', `${playlistItem.pk}`);
            tr.innerHTML = `
        <td class="change-order">&#8597;</td>
        <td class="pk"><input data-pk="${playlistItem.pk}" data-field-name="pk" value='${playlistItem.pk}'></td>
        <td class="playlist-pk"><input data-pk="${playlistItem.pk}" data-field-name="playlist_pk" value='${playlistItem.playlist_pk}'></td>
        <td class="file-path"><input data-pk="${playlistItem.pk}" data-field-name="file_path" value='${playlistItem.file_path}'></td>
        <td class="start-at"><input data-pk="${playlistItem.pk}" data-field-name="start_at" value='${playlistItem.start_at}'></td>
        <td class="end-at"><input data-pk="${playlistItem.pk}" data-field-name="end_at" value='${playlistItem.end_at}'></td>
        <td class="duration"><input data-pk="${playlistItem.pk}" data-field-name="duration" value='${playlistItem.duration}'></td>
        <td class="buttons"><button class="delete" data-pk="${playlistItem.pk}">delete</button></td>
      `;
            (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, 'button.delete').addEventListener('click', () => {
                if (confirm(`are you sure to delete: ${playlistItem.file_path}?`)) {
                    this.tableBody.removeChild(tr);
                }
            });
            (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, 'td.change-order').addEventListener('mousedown', (e) => {
                e.preventDefault(); // Disable text selection.
                this.movingTr = tr;
                this.classList.add('changing-order');
            });
            tr.addEventListener('mouseenter', () => {
                if (this.movingTr !== undefined && this.movingTr !== tr) {
                    tr.querySelectorAll('td').forEach(td => td.classList.add('changing-order'));
                }
            });
            tr.addEventListener('mouseleave', () => {
                if (this.movingTr !== undefined && this.movingTr !== tr) {
                    tr.querySelectorAll('td').forEach(td => td.classList.remove('changing-order'));
                }
            });
            tr.addEventListener('mouseup', () => {
                if (this.movingTr !== undefined && this.movingTr !== tr) {
                    this.tableBody.insertBefore(this.movingTr, tr.nextSibling);
                }
            });
            this.tableBody.appendChild(tr);
        });
    }
    get value() {
        const ret = [];
        this.tableBody.querySelectorAll('tr').forEach((tr, i) => {
            ret.push({
                pk: Number((0, lib_es2015_1.getQuerySelectOrThrowError)(tr, `input[data-field-name="pk"]`).value),
                playlist_pk: Number((0, lib_es2015_1.getQuerySelectOrThrowError)(tr, `input[data-field-name="playlist_pk"]`).value),
                order: i + 1,
                file_path: (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, `input[data-field-name="file_path"]`).value,
                start_at: (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, `input[data-field-name="start_at"]`).value,
                end_at: (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, `input[data-field-name="end_at"]`).value,
                duration: (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, `input[data-field-name="duration"]`).value,
            });
        });
        return ret;
    }
}
exports.PlaylistItemEdit = PlaylistItemEdit;
const definePlaylistItemEdit = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, PlaylistItemEdit);
};
exports.definePlaylistItemEdit = definePlaylistItemEdit;
