"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineExtractAudio = exports.ExtractAudio = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const apis_1 = require("../apis");
const common_1 = require("./common");
class ExtractAudio extends HTMLElement {
    constructor() {
        super();
        this.fileManagerApis = new apis_1.FileManagerApis();
        this.showPopup = (dirEntry, path) => {
            this.dirEntry = dirEntry;
            this.path = path;
            this.targetVideoFilePathSpan.innerHTML = this.getTargetFilePath();
            this.style.setProperty('display', 'flex');
        };
        this.hidePopup = () => {
            this.dirEntry = undefined;
            this.path = undefined;
            this.style.setProperty('display', 'none');
        };
        this.getTargetFilePath = () => {
            if (this.dirEntry === undefined || this.path === undefined) {
                return '';
            }
            return `${this.path}/${this.dirEntry.name}`;
        };
        this.innerHTML = `<div class="popup">
      <div class="popup-sub-container">
        Range(hh:mm:ss - hh:mm:ss) of video (<span class="target-file-path"></span>):<br> 
        <input type="text" class="start-at" value="00:00:00" /> - <input type="text" class="end-at" value="00:00:00" />
        <button class="extract-audio">Extract Audio</button>
      </div>
    </div>`;
        this.popup = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.popup');
        this.targetVideoFilePathSpan = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'span.target-file-path');
        this.startAtInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.start-at');
        this.endAtInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.end-at');
        (0, common_1.setPopupBackgroundStyle)(this);
        (0, common_1.setPopupStyle)(this.popup);
        this.addEventListener('click', () => {
            this.hidePopup();
        });
        this.popup.addEventListener('click', (e) => {
            // ignore the click event on 'this' when user clicks the 'this.popup'.
            e.stopPropagation();
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.extract-audio').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                if (this.dirEntry === undefined) {
                    alert('dirEntry is undefined');
                    return;
                }
                if (this.path === undefined) {
                    alert('path is undefined');
                    return;
                }
                const resp = yield this.fileManagerApis.extractAudio(this.getTargetFilePath(), this.startAtInput.value, this.endAtInput.value);
                if (resp === null) {
                    alert('fail to request extracting audio');
                    return;
                }
                alert('requested extracting audio successfully!');
                this.hidePopup();
            }))();
        });
    }
}
exports.ExtractAudio = ExtractAudio;
const defineExtractAudio = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, ExtractAudio);
};
exports.defineExtractAudio = defineExtractAudio;
