"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileManagerApis = void 0;
const Constants = require("./constants");
const lib_es2015_1 = require("@hodol/webapp-common/lib-es2015");
const api_helper_1 = require("../utils/api-helper");
class FileManagerApis {
    constructor() {
        this.list = (request) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}?path=${request.path}&token=${request.token}`, {
                method: 'GET',
            }, (api_helper_1.codeProcessor));
        });
        this.uploadFiles = (formData, uploadEventHandler) => __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status == 200) {
                        resolve(xhr.response.body);
                    }
                    else {
                        // reject(new Error('Error'));
                        resolve(null);
                    }
                };
                xhr.upload.addEventListener('loadstart', uploadEventHandler.loadstart);
                xhr.upload.addEventListener('progress', uploadEventHandler.progress);
                xhr.upload.addEventListener('loadend', uploadEventHandler.loadend);
                xhr.open('POST', `/apis/${Constants.apiPathGroup}/upload-files`);
                xhr.send(formData);
            });
        });
        this.isAtLeastOneFileExist = (filePaths) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/is-at-least-one-file-exist`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ file_paths: filePaths }),
            });
        });
        this.copyFiles = (request) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/copy`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(request),
            }, (api_helper_1.codeProcessor));
        });
        this.deleteFiles = (request) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}`, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(request),
            }, (api_helper_1.codeProcessor));
        });
        this.moveFiles = (request) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/move`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(request),
            }, (api_helper_1.codeProcessor));
        });
        this.renameFile = (oldPath, newPath) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/rename`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ old_path: oldPath, new_path: newPath }),
            }, (api_helper_1.codeProcessor));
        });
        this.createWebCompatibles = (filePaths) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/web-compatibles`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ file_paths: filePaths }),
            }, (api_helper_1.codeProcessor));
        });
        this.createPreviews = (filePaths) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/previews`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ file_paths: filePaths }),
            }, (api_helper_1.codeProcessor));
        });
        this.uploadVideoCover = (formData, uploadEventHandler) => __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status == 200) {
                        resolve(xhr.response.body);
                    }
                    else {
                        // reject(new Error('Error'));
                        resolve(null);
                    }
                };
                xhr.upload.addEventListener('loadstart', uploadEventHandler.loadstart);
                xhr.upload.addEventListener('progress', uploadEventHandler.progress);
                xhr.upload.addEventListener('loadend', uploadEventHandler.loadend);
                xhr.open('POST', `/apis/${Constants.apiPathGroup}/upload-video-cover`);
                xhr.send(formData);
            });
        });
        this.createVideoCoverByScene = (path, seekAt) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/create-video-cover-by-scene`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ path, seek_at: seekAt }),
            }, (api_helper_1.codeProcessor));
        });
        this.extractAudio = (path, startAt, endAt) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/extract-audio`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ path, start_at: startAt, end_at: endAt }),
            }, (api_helper_1.codeProcessor));
        });
        this.extractGif = (path, startAt, endAt, height, fps, loop) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/extract-gif`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ path, start_at: startAt, end_at: endAt, height, fps, loop }),
            }, (api_helper_1.codeProcessor));
        });
    }
}
exports.FileManagerApis = FileManagerApis;
