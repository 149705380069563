"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineAddToPlaylist = exports.AddToPlaylist = void 0;
const apis_1 = require("../../playlist/apis");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const common_1 = require("./common");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
class AddToPlaylist extends HTMLElement {
    constructor() {
        super();
        this.playlistApis = new apis_1.PlaylistApis();
        this.playlistItemPaths = [];
        this.showPopup = (playlistItemPaths) => {
            this.playlistItemPaths = playlistItemPaths;
            this.style.setProperty('display', 'flex');
        };
        this.hidePopup = () => {
            this.playlistItemPaths = [];
            this.style.setProperty('display', 'none');
        };
        this.innerHTML = `<div class="popup">
      <div class="popup-sub-container">
        <select class="playlist">
          <option value="-1">Select...</option>
        </select>
        <button class="add">Add</button>
      </div>
    </div>`;
        this.popup = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.popup');
        this.playlistSelect = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'select.playlist');
        (0, common_1.setPopupBackgroundStyle)(this);
        (0, common_1.setPopupStyle)(this.popup);
        this.addEventListener('click', () => {
            this.hidePopup();
        });
        this.popup.addEventListener('click', (e) => {
            // ignore the click event on 'this' when user clicks the 'this.popup'.
            e.stopPropagation();
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.add').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.playlistApis.insertPlaylistItemPaths(Number(this.playlistSelect.value), this.playlistItemPaths);
                if (resp === null) {
                    alert(`fail to insert files to playlist (${this.playlistSelect.value})`);
                    return;
                }
                alert(`insert files to playlist successfully!`);
                this.hidePopup();
            }))();
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.playlistApis.listPlaylists({ page_no: 0, page_size: 0, query: '' });
            if (resp === null) {
                alert(`fail to get playlists`);
                return;
            }
            resp.items.forEach(playlist => {
                const opt = document.createElement('OPTION');
                opt.value = `${playlist.pk}`;
                opt.innerHTML = playlist.name;
                this.playlistSelect.appendChild(opt);
            });
        });
    }
}
exports.AddToPlaylist = AddToPlaylist;
const defineAddToPlaylist = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, AddToPlaylist);
};
exports.defineAddToPlaylist = defineAddToPlaylist;
