"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineManageDirEntry = exports.ManageDirEntry = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const service_file_type_1 = require("../service-file-type");
class ManageDirEntry extends HTMLElement {
    constructor() {
        super();
        this.svcFileType = service_file_type_1.ServiceFileType.Unknown;
        this.showPopup = (dirEntry, path, clientX, clientY) => {
            this.dirEntry = dirEntry;
            this.path = path;
            if (!this.dirEntry.is_dir) {
                const ext = dirEntry.name.substring(dirEntry.name.lastIndexOf('.') + 1).toLowerCase();
                this.svcFileType = (0, service_file_type_1.isServiceFileType)(ext);
                this.createWebCompatibleBtn.style.setProperty('display', service_file_type_1.WebCompatibleCreatable.includes(this.svcFileType) ? 'block' : 'none');
                this.createPreviewBtn.style.setProperty('display', service_file_type_1.PreviewCreatable.includes(this.svcFileType) ? 'block' : 'none');
                [this.uploadVideoCoverBtn, this.setVideoCoverBySceneBtn, this.extractAudioBtn, this.extractGifBtn].forEach(btn => {
                    btn.style.setProperty('display', this.svcFileType === service_file_type_1.ServiceFileType.Video ? 'block' : 'none');
                });
            }
            this.style.setProperty('display', 'block');
            if (clientX > (document.body.clientWidth - 250)) {
                this.popup.style.setProperty('right', `${document.body.clientWidth - clientX}px`);
            }
            else {
                this.popup.style.setProperty('left', `${clientX}px`);
            }
            if (clientY > (document.body.clientHeight - 350)) {
                this.popup.style.setProperty('bottom', `${document.body.clientHeight - clientY}px`);
            }
            else {
                this.popup.style.setProperty('top', `${clientY}px`);
            }
        };
        this.hidePopup = () => {
            this.dirEntry = undefined;
            this.path = undefined;
            this.svcFileType = service_file_type_1.ServiceFileType.Unknown;
            this.style.setProperty('display', 'none');
            this.popup.style.setProperty('right', '');
            this.popup.style.setProperty('left', '');
            this.popup.style.setProperty('top', '');
            this.popup.style.setProperty('bottom', '');
        };
        this.style.setProperty('display', 'none');
        this.style.setProperty('position', 'fixed');
        this.style.setProperty('left', '0');
        this.style.setProperty('top', '0');
        this.style.setProperty('width', '100%');
        this.style.setProperty('height', '100%');
        this.innerHTML = `<div class="popup">
      <button class="open-sharing-popup">Share</button>
      <button class="download-file">Download</button>
      <button class="rename-item">Rename</button>
      <button class="copy-item">Copy</button>
      <button class="move-item">Move</button>
      <button class="delete-item">Delete</button>
      
      <button class="create-web-compatible">Create Web Compatible</button>
      <button class="create-preview">Create Preview</button>
      
      <button class="video upload-cover">Upload Cover</button>
      <button class="video set-cover-by-scene">Set Cover By Scene</button>
      <button class="video extract-audio">Extract Audio</button>
      <button class="video extract-gif">Extract GIF</button>
      
      <button class="add-to-playlist">Add to Playlist</button>
    </div>`;
        this.popup = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.popup');
        this.popup.style.setProperty('position', 'fixed');
        this.popup.style.setProperty('display', 'inline-flex');
        this.popup.style.setProperty('flex-direction', 'column');
        this.addEventListener('click', () => {
            this.hidePopup();
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.open-sharing-popup').addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('share-button-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.download-file').addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            if (this.dirEntry.is_dir) {
                alert(`can not download directory`);
                return;
            }
            this.dispatchEvent(new CustomEvent('download-button-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.copy-item').addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('copy-button-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.move-item').addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('move-button-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.delete-item').addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('delete-button-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.rename-item').addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('rename-button-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        this.createWebCompatibleBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.create-web-compatible');
        this.createWebCompatibleBtn.addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('create-web-compatible-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        this.createPreviewBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.create-preview');
        this.createPreviewBtn.addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('create-preview-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        this.uploadVideoCoverBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.video.upload-cover');
        this.uploadVideoCoverBtn.addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('upload-cover-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        this.setVideoCoverBySceneBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.video.set-cover-by-scene');
        this.setVideoCoverBySceneBtn.addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('set-cover-by-scene-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        this.extractAudioBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.video.extract-audio');
        this.extractAudioBtn.addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('extract-audio-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        this.extractGifBtn = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.video.extract-gif');
        this.extractGifBtn.addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('extract-gif-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this.popup, 'button.add-to-playlist').addEventListener('click', () => {
            if (this.dirEntry === undefined) {
                alert(`dirEntry is undefined`);
                return;
            }
            if (this.path === undefined) {
                alert(`path is undefined`);
                return;
            }
            this.dispatchEvent(new CustomEvent('add-to-playlist-click', {
                detail: {
                    dirEntry: this.dirEntry,
                    path: this.path,
                },
            }));
        });
    }
}
exports.ManageDirEntry = ManageDirEntry;
const defineManageDirEntry = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, ManageDirEntry);
};
exports.defineManageDirEntry = defineManageDirEntry;
