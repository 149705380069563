"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineViews = exports.routes = void 0;
const home_page_1 = require("../main/home-page");
const list_1 = require("../file-manager/list");
const FileManagerConstants = require("../file-manager/constants");
const login_1 = require("../user/login");
const logout_1 = require("../user/logout");
const user_edit_1 = require("../user/user-edit");
const list_2 = require("../user/list");
const UserConstants = require("../user/constants");
const playlist_edit_1 = require("../playlist/playlist-edit");
const list_3 = require("../playlist/list");
const PlaylistConstants = require("../playlist/constants");
const install_page_1 = require("../install/install-page");
const InstallConstants = require("../install/constants");
const entrance_list_1 = require("../file-permission/entrance-list");
const FilePermissionConstants = require("../file-permission/constants");
const task_list_1 = require("../task/task-list");
const TaskConstants = require("../task/constants");
exports.routes = [
    {
        path: '/web',
        tag: 'home-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${FileManagerConstants.routerPathGroup}/list`,
        tag: 'file-list',
        pathParamNames: [],
        queryParamNames: ['path', 'token'],
    },
    {
        path: `/web/${FilePermissionConstants.routerPathGroup}/entrances`,
        tag: 'entrance-list',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${UserConstants.routerPathGroup}/login`,
        tag: 'user-login',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${UserConstants.routerPathGroup}/logout`,
        tag: 'user-logout',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${UserConstants.routerPathGroup}/list`,
        tag: 'user-list',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${UserConstants.routerPathGroup}/edit/:user-pk`,
        tag: 'user-edit',
        pathParamNames: ['user-pk'],
        queryParamNames: [],
    },
    {
        path: `/web/${PlaylistConstants.routerPathGroup}/list`,
        tag: 'playlist-list',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${PlaylistConstants.routerPathGroup}/edit/:playlist-pk`,
        tag: 'playlist-edit',
        pathParamNames: ['playlist-pk'],
        queryParamNames: [],
    },
    {
        path: `/web/${InstallConstants.routerPathGroup}`,
        tag: 'install-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${TaskConstants.routerPathGroup}/list`,
        tag: 'task-list',
        pathParamNames: [],
        queryParamNames: [],
    },
];
const defineViews = () => {
    (0, list_1.defineFileList)('file-list');
    (0, login_1.defineUserLogin)('user-login');
    (0, logout_1.defineUserLogout)('user-logout');
    (0, list_2.defineUserList)('user-list');
    (0, user_edit_1.defineUserEdit)('user-edit');
    (0, list_3.definePlaylistList)('playlist-list');
    (0, playlist_edit_1.definePlaylistEdit)('playlist-edit');
    (0, install_page_1.defineInstallPage)('install-page');
    (0, entrance_list_1.defineEntranceList)('entrance-list');
    (0, task_list_1.defineTaskList)('task-list');
    (0, home_page_1.defineHomePage)('home-page');
};
exports.defineViews = defineViews;
