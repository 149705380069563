"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.definePlaylistViewer = exports.PlaylistViewer = exports.defineMangaViewer = exports.MangaViewer = void 0;
const main_frame_1 = require("./main/main-frame");
(0, main_frame_1.define)('main-frame');
var manga_viewer_1 = require("./manga-viewer/manga-viewer");
Object.defineProperty(exports, "MangaViewer", { enumerable: true, get: function () { return manga_viewer_1.MangaViewer; } });
Object.defineProperty(exports, "defineMangaViewer", { enumerable: true, get: function () { return manga_viewer_1.define; } });
var playlist_viewer_1 = require("./playlist-viewer/playlist-viewer");
Object.defineProperty(exports, "PlaylistViewer", { enumerable: true, get: function () { return playlist_viewer_1.PlaylistViewer; } });
Object.defineProperty(exports, "definePlaylistViewer", { enumerable: true, get: function () { return playlist_viewer_1.define; } });
