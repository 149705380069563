"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineYoutubeDownload = exports.YoutubeDownload = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const apis_1 = require("../../youtube-download/apis");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const common_1 = require("./common");
class YoutubeDownload extends HTMLElement {
    constructor() {
        super();
        this.youtubeDownloadApis = new apis_1.YoutubeDownloadApis();
        this.showPopup = (path) => {
            this.path = path;
            this.style.setProperty('display', 'flex');
        };
        this.hidePopup = () => {
            this.path = undefined;
            this.style.setProperty('display', 'none');
        };
        this.innerHTML = `<div class="popup">
      <div class="popup-sub-container">
        Video URL: <input class="video-url" />
        <button class="download">Download</button>
      </div>
    </div>`;
        this.popup = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.popup');
        this.urlInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.video-url');
        (0, common_1.setPopupBackgroundStyle)(this);
        (0, common_1.setPopupStyle)(this.popup);
        this.addEventListener('click', () => {
            this.hidePopup();
        });
        this.popup.addEventListener('click', (e) => {
            // ignore the click event on 'this' when user clicks the 'this.popup'.
            e.stopPropagation();
        });
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.download').addEventListener('click', () => {
            void (() => __awaiter(this, void 0, void 0, function* () {
                if (this.path === undefined) {
                    alert('path is undefined');
                    return;
                }
                const resp = yield this.youtubeDownloadApis.addDownloadQueue({ video_url: this.urlInput.value, save_to: this.path });
                if (resp === null) {
                    alert('fail to request downloading');
                    return;
                }
                alert('requested downloading successfully!');
                this.hidePopup();
            }))();
        });
    }
}
exports.YoutubeDownload = YoutubeDownload;
const defineYoutubeDownload = (name) => {
    (0, lib_es2015_2.defineIfUndefined)(name, YoutubeDownload);
};
exports.defineYoutubeDownload = defineYoutubeDownload;
