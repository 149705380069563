"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineDirEntryList = exports.DirEntryList = void 0;
const dir_entry_1 = require("./dir-entry");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const preview_box_1 = require("./preview-box");
class DirEntryList extends HTMLElement {
    constructor() {
        super();
        this.path = '';
        this.allCheckboxes = [];
        this.createTableRowByDirEntry = (dirEntry, index) => {
            const tr = document.createElement('TR');
            tr.setAttribute('data-index', `${index}`);
            tr.classList.add('dir-entry');
            tr.innerHTML = `
      <td class="checkbox dir-entry">
        <input class="checkbox dir-entry" type="checkbox" data-index='${index}' ${dirEntry.name === '..' ? 'disabled' : ''}>
      </td>
      <td class="preview dir-entry">
        <preview-box target="${this.path}/${dirEntry.name}" is-dir="${dirEntry.is_dir}"></preview-box>
      </td>
      <td class="size dir-entry">${this.humanReadableSize(dirEntry.size)}</td>
      <td class="modified-at dir-entry">${this.humanReadableTime(dirEntry.modified_at)}</td>
      <td class="name dir-entry">
        <div class="cell name dir-entry">
          <div>${dirEntry.name}</div>
          <button class="manage-dir-entry">&vellip;</button>
        </div>
      </td>`;
            const checkbox = (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, 'input.checkbox.dir-entry');
            if (dirEntry.name !== '..')
                this.allCheckboxes.push(checkbox);
            checkbox.addEventListener('change', (e) => {
                e.stopPropagation();
                let isAllChecked = true;
                this.allCheckboxes.forEach((checkbox) => {
                    if (checkbox.checked)
                        return;
                    isAllChecked = false;
                });
                this.globalCheckbox.checked = isAllChecked;
            });
            (0, lib_es2015_1.getQuerySelectOrThrowError)(tr, 'button.manage-dir-entry').addEventListener('click', (e) => {
                e.stopPropagation();
                this.dispatchEvent(new CustomEvent('click-manage-dir-entry-button', {
                    detail: {
                        clientX: e.clientX,
                        clientY: e.clientY,
                        dirEntry,
                    },
                }));
            });
            tr.querySelectorAll('td:not(.checkbox)').forEach(td => {
                td.addEventListener('click', () => this.dispatchEvent(new CustomEvent('click-dir-entry', { detail: dirEntry })));
            });
            return tr;
        };
        this.setData = (path, dirEntries) => {
            this.reset();
            this.path = path;
            this.dirEntries = dirEntries;
            if (this.path !== '/') {
                const tr = this.createTableRowByDirEntry(Object.assign(Object.assign({}, (0, dir_entry_1.newDirEntry)()), { name: '..', is_dir: true }), -1);
                this.tableBody.appendChild(tr);
            }
            this.dirEntries.forEach((dirEntry, i) => {
                const tr = this.createTableRowByDirEntry(dirEntry, i);
                this.tableBody.appendChild(tr);
            });
        };
        this.reset = () => {
            this.path = '';
            this.dirEntries = [];
            this.allCheckboxes = [];
            this.tableBody.innerHTML = '';
        };
        this.humanReadableSize = (size) => {
            const unit = [' B', 'KB', 'MB', 'GB', 'PB'];
            let unitIdx = 0;
            let quotient = size;
            while (quotient > 1024 && unitIdx < 5) {
                unitIdx++;
                quotient = quotient / 1024;
            }
            return `${quotient.toFixed(2)} ${unit[unitIdx]}`;
        };
        //2024-12-24T16:00:38+09:00 or 2024-12-24T16:00:38.012+09:00
        this.humanReadableTime = (isoTimeString) => {
            if (isoTimeString === '')
                return '';
            return isoTimeString.replace('T', '<br>').replace(/\+.*$/, '').replace(/\..*$/, '');
        };
        this.getSelectedDirEntries = () => {
            const checkIndex = this.allCheckboxes
                .filter((checkbox) => checkbox.checked)
                .map((checkbox) => Number(checkbox.dataset.index));
            return this.dirEntries.filter((dirEntry, i) => checkIndex.includes(i) && !['', '.', '..'].includes(dirEntry.name));
        };
        this.innerHTML = `
    <table style='width: 100%;'>
      <thead style='position: sticky; top:0;'>
        <tr>
          <td class="checkbox header">
            <input class="checkbox header" type="checkbox">
          </td>
          <td class="preview header">preview</td>
          <td class="size header">size</td>
          <td class="modified-at header">modified at</td>
          <td class="name header">name</td></tr>
      </thead>
      <tbody></tbody>
    </table>
    `;
        this.table = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'table');
        this.tableBody = (0, lib_es2015_1.getQuerySelectOrThrowError)(this.table, 'tbody');
        this.globalCheckbox = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.checkbox.header');
        this.globalCheckbox.addEventListener('change', () => {
            this.allCheckboxes.forEach((checkbox) => checkbox.checked = this.globalCheckbox.checked);
        });
    }
}
exports.DirEntryList = DirEntryList;
const defineDirEntryList = (name) => {
    (0, preview_box_1.definePreviewBox)('preview-box');
    (0, lib_es2015_2.defineIfUndefined)(name, DirEntryList);
};
exports.defineDirEntryList = defineDirEntryList;
