"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineToastBox = exports.ToastBox = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
class ToastBox extends HTMLElement {
    constructor() {
        super();
        this.resetTimerId = 0;
        this.show = (isFadeout) => {
            this.style.setProperty('display', 'block');
            if (isFadeout) {
                if (this.resetTimerId !== 0) {
                    this.classList.remove('fadeout');
                    void this.offsetWidth; // This is trick part of restarting css animation: https://css-tricks.com/restart-css-animation/
                    clearTimeout(this.resetTimerId);
                    this.resetTimerId = 0;
                }
                this.classList.add('fadeout');
                this.resetTimerId = window.setTimeout(() => {
                    this.hide();
                }, 1000);
            }
        };
        this.hide = () => {
            this.style.setProperty('display', 'none');
            this.classList.remove('fadeout');
            this.resetTimerId = 0;
        };
        this.setMessage = (message) => {
            this.innerHTML = message;
        };
    }
}
exports.ToastBox = ToastBox;
const defineToastBox = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, ToastBox);
};
exports.defineToastBox = defineToastBox;
